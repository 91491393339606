import React, { memo } from "react";
import logo from "../assets/logo512.png";

function SiteFooter(props) {
  const sendMessage = (e) => {
    e.preventDefault();
    console.log(e.target.form_message.value)
    window.location.href = `mailto:jehangir30@hotmail.com?subject=Enquiry to Islamic Care Inc.&body=${e.target.form_message.value}`;
  }
  return (
    <footer id="footer" className="footer pb-0">
      <div className="container pt-90 pb-60">
        <div className="row">
          <div className="col-sm-6 col-md-3">
            <img alt="" src={logo} />
          </div>
          <div className="col-sm-6 col-md-3">
            <div className="widget dark">
              <h5 className="widget-title line-bottom-theme-colored-2">
                Useful Links
              </h5>
              <div className="latest-posts">
                <article className="post media-post clearfix pb-0 mb-10">
                  <div className="post-right">
                    <h5 className="post-title mt-0 mb-5">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.masjidibrahim.com.au/"
                      >
                        Masjid Ibrahim
                      </a>
                    </h5>
                  </div>
                </article>
                <article className="post media-post clearfix pb-0 mb-10">
                  <div className="post-right">
                    <h5 className="post-title mt-0 mb-5">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://moonsightingaustralia.info/"
                      >
                        Moon Sightening Australia
                      </a>
                    </h5>
                  </div>
                </article>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-3">
            <div className="widget dark">
              <h5 className="widget-title line-bottom-theme-colored-2">
                Quick Contact
              </h5>
              <form
                id="footer_quick_contact_form"
                name="footer_quick_contact_form"
                className="quick-contact-form"
                method="post"
                onSubmit={sendMessage}
              >
                <div className="form-group">
                  <textarea
                    name="form_message"
                    className="form-control"
                    required=""
                    placeholder="Enter Message"
                    rows="3"
                  ></textarea>
                </div>
                <div className="form-group">
                  <button
                    type="submit"
                    className="btn btn-default btn-transparent btn-xs btn-flat mt-0"
                    data-loading-text="Please wait..."
                  >
                    Send Message
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="footer-contact">
          <div>
            <h5 className="text-white">Call Us</h5>
            <a href="tel:0422634456">Phone: 0422634456</a>
          </div>
          <div>
            <h5 className="text-white">Address</h5>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://maps.google.com/?q=840 North Lake Road Cockburn Central 6164 WA&t=m"
            >
              840 North Lake Road Cockburn Central 6164 WA
            </a>
          </div>
          <div>
            <h5 className="text-white">Email</h5>
            <p>
              <a href="mailto:jehangir30@hotmail.com">
                jehangir30@hotmail.com
              </a>
            </p>
          </div>
          <div>
            <h5 className="text-white">Admin</h5>
            <p>
            <a target="_blank" href="https://admin.islamiccare.com.au">
                Click here!
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-theme-colored p-20">
        <div className="row text-center">
          <div className="col-md-12">
            <p className="text-white font-11 m-0">
              Copyright &copy;2021 Australian Islamic Care INC. All Rights
              Reserved
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default memo(SiteFooter);
