import React from "react";
import { Link } from "react-router-dom"
function DonationSuccessful(props) {
  return (
    <div className="donation-success">
      <h1>Jazak Allah</h1>
      <h4>
        Your donation is received successfully.<br />
        May Allah accept your donation<br />
        and give you highest reward
      </h4>
      <Link to="/">Go back</Link>
    </div>
  );
}

export default DonationSuccessful;
