import React from "react";

function Announcement({ dateTime, title, address, id, fileLink }) {
  
  return (
    <div className="horizontal-timeline">
      <div className="period">
        <span>{dateTime}</span>
      </div>
      <i className="fa fa-bullhorn text-theme-colored"></i>
      <h3 className="title">{title}</h3>
      <h5 className="sub-title text-theme-colored">
        <a
          className="sub-title text-theme-colored"
          target="_blank"
          rel="noopener noreferrer"
          href={`https://maps.google.com/?q=${address}&t=m`}
        >
          {address}
        </a>
      </h5>
      <p>
      <a rel="noopener noreferrer" href={`news/${id}`}>Program Details...</a>
      </p>
    </div>
  );
}

export default Announcement;
