import React, {memo} from "react";
import AboutImage from "../assets/latestAic.jpeg";
function About(props) {
  return (
    <>
      <section id="about" className="bg-theme-colored">
        <div className="container pt-0 pb-20">
          <div className="row">
            <div className="call-to-action pt-20 pb-20">
              <div className="col-xs-12 col-sm-6 col-md-4 mb-sm-30">
                <i className="fa fa-certificate text-white font-36 pull-left flip mt-15 mr-20"></i>
                <h4 className="font-16 font-weight-600 text-white">
                  United Community
                </h4>
                <h6 className="text-white">is stronger community</h6>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4 mb-sm-30">
                <i className="fa fa-map-marker text-white font-36 pull-left flip mt-15 mr-20"></i>
                <h4 className="font-16 font-weight-600 text-white">
                  You can Find Us At
                </h4>
                <h6 className="text-white">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://maps.google.com/?q=840 North Lake Road Cockburn Central 6164 WA&t=m`}
                  >
                    840 North Lake Road Cockburn Central 6164 WA
                  </a>
                </h6>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <i className="fa fa-phone text-white font-36 pull-left flip mt-15 mr-20"></i>
                <h4 className="font-16 font-weight-600 text-white">
                  <a href="tel:0422634456">Contact Us : 0422634456</a>
                </h4>
                <h6 className="text-white">You Can Contact Us Anytime</h6>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="container pb-0">
          <div className="section-content">
            <div className="row">
              <div className="col-md-4">
                <img src={AboutImage} alt="" />
              </div>
              <div className="col-md-8">
                <h3 className="text-gray">
                  Australian Islamic Care INC. (AICI)
                </h3>
                <h2 className="mt-0 text-theme-colored">
                The objectives of the Association are:
                </h2>
                <p className="font-weight-600">
                  <strong>Advancing Religious teaching and establishment of a place of
                  worship for Muslims:</strong> AICI is aiming to establish the place of
                  worship for Muslims (Centre for Islamic and Interfaith
                  services) and use this place to promote interfaith
                  interactions amongst people of different faiths. The Islamic
                  education and practices of worships will be according to Sunni
                  traditions. AICI will help its members in observing and
                  celebrating important events in the Islamic calendar.
                </p>
                <p>
                  <strong>Advancing Education:</strong> AICI will arrange regular free of cost
                  coaching/educational sessions for school aged students coming
                  from low socioeconomical communities and new migrants for
                  better academic performance and to achieve their best
                  potential.
                </p>
                <p>
                  <strong>Community Welfare Work:</strong> AICI is aiming at providing free food
                  to poor and homeless people and arrange other basic
                  necessities such as clothes, shoes and toiletries according to
                  our budget.
                </p>
                <p>
                  <strong>Health care and Wellbeing:</strong> AICI aims at aiming at promoting
                  physical health and mental wellbeing of adolescents and
                  teenagers by organising lectures, workshops and Seminars about
                  drugs and alcohol abuse and avoiding teenage pregnancies. AICI
                  is aiming to arrange free health clinics for medical services
                  which are not covered by Medicare such as dental care and
                  psychological services. We will also help in providing free
                  general health checks for new migrants who are not eligible
                  for Medicare benefits. These clinics will offer one to one
                  consults or informative group session. These will be day trips
                  to the clinic.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default memo(About);
