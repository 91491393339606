import React, { memo } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Hero from "../components/Hero";
import SiteFooter from "../components/SiteFooter";
import SiteHeader from "../components/SiteHeader";
import About from "./About";
import Announcements from "./Announcements";
import Gallery from "./Gallery"
import Prayers from "./Prayers";
import Team from './Team';
import IslamicCalendar from "./IslamicCalendar";
import Projects from "./Projects";
import { STRIPE_PUBLIC_KEY } from "../Constants"
const STRIPE = loadStripe(STRIPE_PUBLIC_KEY)

function Home(props) {
  return (
    <div id="home" className="home">
      <Elements stripe={STRIPE}>
        <SiteHeader />
      </Elements>
      <Hero />
      <About />
      <Announcements />
      <Gallery />
      <Projects />
      <Prayers />
      <IslamicCalendar />
      <Team />
      <SiteFooter />
    </div>
  );
}

export default memo(Home);
