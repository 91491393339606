import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
function GalleryItem({ dateTime, title, address, photos }) {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const images = ["jpg", "jpeg", "gif", "png"]
  const videos = ["mp4", "mp3", "3gp", "ogg", "mov"]
  const imageList = []
  const videoList = []
  photos.forEach((f) => {
    if (videos.includes(f.substring(f.lastIndexOf(".") + 1, f.length))) {
      videoList.push(f)
    }
    if (images.includes(f.substring(f.lastIndexOf(".") + 1, f.length))) {
      imageList.push(f)
    }
  })
  return (
    <div className="event">
      <div className="event-media">
        {videoList.map((f) => (
          <div key={f}>
            {videos.includes(f.substring(f.lastIndexOf(".") + 1, f.length)) &&
              <video src={f} width={480} controls>
                <source src={f} type="video/mp4" />
                <source src={f} type="video/ogg" />
                Your browser does not support the video tag.
              </video>}
          </div>
        ))}
      </div>
      <div className="event-media">
        {imageList.map((f, idx) => (
          <div key={f}>
            {images.includes(f.substring(f.lastIndexOf(".") + 1, f.length)) &&
              <img
                src={f}
                alt=""
                onClick={() => {
                  setIsOpen(true);
                  setPhotoIndex(idx);
                }}
              />}
          </div>
        ))}

        {isOpen && (
          <Lightbox
            mainSrc={imageList[photoIndex]}
            nextSrc={imageList[(photoIndex + 1) % imageList.length]}
            prevSrc={imageList[(photoIndex + imageList.length - 1) % imageList.length]}
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + imageList.length - 1) % imageList.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % imageList.length)
            }
          />
        )}
      </div>
      <div>
        <h4>{title}</h4>
        <p>{dateTime}</p>
        <p>{address}</p>
      </div>
    </div>
  );
}

export default GalleryItem;
