import { useEffect, useState } from "react";
import { SERVER_ADDRESS } from "../Constants";

function useFetch(url) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await fetch(SERVER_ADDRESS + url);
        const json = await res.json();
        setData(json.data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };
    fetchData();
  }, [url]);
  return { loading, data, error };
}

export default useFetch;
