import { sunriseset } from "./sunrise";
import moment from "moment";
import { LATITUDE, LONGITUDE} from "../../Constants"

export default function buildCalendar(value, hijriCalendar, setHijriMonthYear) {
  const startDay = value.clone().startOf("month").startOf("week");
  const endDay = value.clone().endOf("month").endOf("week");
  const day = startDay.clone().subtract(1, "day");
  const gregDay = hijriCalendar.toGregorian();
  const dayCount = day.diff(gregDay) / (24 * 60 * 60 * 1000);
  hijriCalendar.add(dayCount, 'day')
  
  const calendar = [];
  while (day.isBefore(endDay, "day")) {
    calendar.push(
      Array(7)
        .fill(0)
        .map(() => {
          const a = [];
          const newDay = day.add(1, "day").clone();
          a.push(newDay);
          const newHijriDay = hijriCalendar.add(1, 'day').clone()
          a.push(newHijriDay);
          const { sunriseMillis, sunsetMillis } = sunriseset(
            day.clone().dayOfYear(),
            LATITUDE,
            LONGITUDE
          );
          a.push(moment(sunriseMillis).format("h:mm"));
          a.push(moment(sunsetMillis).format("h:mm"));
          return a;
        })
    );
  }
  setHijriMonthYear({month: calendar[2][3][1].getMonthName(), year: calendar[2][3][1].getYear()})
  return calendar;
}
