import { memo } from "react";
import Announcement from "../components/Announcement";
import useFetch from "../hooks/useFetch";
import { formatDate } from "../utils";

function Announcements(props) {
  const { loading, error, data } = useFetch("/news");
  const { loading: moonLoading, error: moonError, data: moonData } = useFetch("/newmoon");

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;
  if (moonLoading) return <p>Loading...</p>;
  if (moonError) return <p>Error :(</p>;
  return (
    <section id="announcements" className="bg-silver-light">
      <div className="container">
        <div className="section-title text-center">
          <div className="row">
            <div className="col-md-6 col-md-offset-3">
              <h2 className="line-bottom-centered text-uppercase title">
                Recent{" "}
                <span className="text-theme-colored"> Announcements</span>
              </h2>
              <p>
                AIC organizes different events for muslim community. A snapshot
                of recent announcements is given below
              </p>
            </div>
          </div>
        </div>
        <div className="section-content">
          <div className="row">
            <div className="col-md-12">
              <div className="horizontal-timeline">
                <div className="period">
                  <span>
                    {new Date().toLocaleString("en-US", {
                      weekday: "long",
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    })}
                  </span>
                </div>
                <i className="fa fa-bullhorn text-theme-colored"></i>
                <h3 className="title">{moonData.month}</h3>
                <h5 className="sub-title text-theme-colored">
                  <a
                    className="sub-title text-theme-colored"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://maps.google.com/?q=Perth Western Australia&t=m`}
                  >
                    Perth Western Australia
                  </a>
                </h5>
                <p>
                  <a rel="noopener noreferrer" href={moonData.link}>Details...</a>
                </p>
              </div>
              {data.map((a) => (
                <Announcement
                  key={a._id}
                  id={a._id}
                  dateTime={formatDate(a.startTime, a.endTime)}
                  title={a.heading}
                  address={a.address}
                  fileLink={a.files[0]}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default memo(Announcements);
