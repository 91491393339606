import React, { memo } from "react";
import GalleryItem from "../components/GalleryItem";
import useFetch from "../hooks/useFetch";
import {formatDate} from "../utils"
function Gallery(props) {
  const { loading, error, data } = useFetch("/events");
  
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;
  return (
    <section
      id="gallery"
      className="divider parallax layer-overlay overlay-white-9"
      data-parallax-ratio="0.7"
    >
      <div className="container pb-40">
        <div className="section-content">
          <div className="row">
            <div className="col-md-12">
              <h3 className="title line-bottom line-height-1 mt-0 mb-30">
                <i className="fa fa-camera-retro text-theme-colored mr-10"></i>Our{" "}
                <span className="text-theme-colored">Gallery</span>
              </h3>
              {data.map((evt) => (
                <GalleryItem
                  key={evt._id}
                  eventId={evt._id}
                  dateTime={formatDate(evt.startTime, evt.endTime)}
                  title={evt.heading}
                  address={evt.address}
                  photos={evt.files}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default memo(Gallery);
