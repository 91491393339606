import moment from "moment";
import * as Constans from "../../Constants"

export default class HijriCalendar {
  constructor(hijriData = null) {
    this.day = Constans.HIJRI_START_DAY;
    this.month = Constans.HIJRI_START_MONTH;
    this.year = Constans.HIJRI_START_YEAR;
    this.gregDate = moment(Constans.GREG_START_DATE, "D/M/YYYY");
    this.hijriData = hijriData;
  }
  toGregorian() {
    const temp = new HijriCalendar(this.hijriData);
    let count = 0;
    while (
      temp.getDay() !== this.day ||
      temp.getMonth() !== this.month ||
      temp.getYear() !== this.year
    ) {
      count++;
      temp.add(1, "day");
    }
    return this.gregDate.clone().add(count, "day");
  }
  toHijri(greg) {
    const gregDays = greg.diff(this.gregDate) / (24 * 60 * 60 * 1000);
    const temp = new HijriCalendar();
    return gregDays >= 0 ? temp.add(gregDays, "day") : null;
  }
  clone() {
    return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
  }
  getDay() {
    return this.day;
  }
  getMonth() {
    return this.month;
  }
  getYear() {
    return this.year;
  }

  add(num, label) {
    switch (label) {
      case "day":
        let is29Month = false;
        this.hijriData &&
          this.hijriData.forEach((d) => {
            if (this.month === d.month && this.year === d.year && d.is29Month === true) {
              is29Month = true;
            }
          });
        if (is29Month) {
          if (this.day + num <= 29) {
            this.day += num;
          } else {
            this.month += 1;
            if (this.month > 12) {
              this.year += 1;
              this.month -= 12;
            }
            if (num - 29 > 0) {
              this.add(num - 29, "day");
            } else {
              this.day = this.day + num - 29;
            }
          }
        } else {
          if (this.day + num <= 30) {
            this.day += num;
          } else {
            this.month += 1;
            if (this.month > 12) {
              this.year += 1;
              this.month -= 12;
            }
            if (num - 30 > 0) {
              this.add(num - 30, "day");
            } else {
              this.day = this.day + num - 30;
            }
          }
        }
        break;
      default:
    }
    return this;
  }

  subtract(num, label) {
    switch (label) {
      case "day":
        if (this.day - num > 0) {
          this.day -= num;
        } else {
          this.month -= 1;
          if (this.month <= 0) {
            this.year -= 1;
            this.month += 12;
          }
          let is29Month = false;
          this.hijriData &&
            this.hijriData.forEach((d) => {
              if (this.month === d.month && this.year === d.year && d.is29Month === true) {
                is29Month = true;
              }
            });
          if (is29Month) {
            if (this.day - num + 29 > 0) {
              this.day = this.day - num + 29;
            } else {
              this.subtract(num - 29, "day");
            }
          } else {
            if (this.day - num + 30 > 0) {
              this.day = this.day - num + 30;
            } else {
              this.subtract(num - 30, "day");
            }
          }
        }
        break;
      default:
    }
    return this;
  }
  toString() {
    return this.day + "/" + this.month + "/" + this.year;
  }
  getMonthName() {
    switch (this.month) {
      case 1:
        return "Muharram";
      case 2:
        return "Safar";
      case 3:
        return "Rabi-Al-Awal";
      case 4:
        return "Rabi-Al-Sani";
      case 5:
        return "Jamadi-al-Awal";
      case 6:
        return "Jamadi-Al-Sani";
      case 7:
        return "Rajab";
      case 8:
        return "Shaban";
      case 9:
        return "Ramadhan";
      case 10:
        return "Shawal";
      case 11:
        return "Ze-Al-Qad";
      case 12:
        return "Ze-Al-Haj";
      default:
        return null;
    }
  }
}
