import React, { useState, createContext, useContext } from "react";
const StoreContext = createContext();
export const StoreProvider = (props) => {
  const [homePage, setHomePage] = useState(true);
  const state = {
    homePage,
    setHomePage,
  };
  return (
    <StoreContext.Provider value={state}>
      {props.children}
    </StoreContext.Provider>
  );
};

export const useStoreContext = () => useContext(StoreContext);
