import React, { useState, useEffect } from "react";
import moment from "moment";
import "./Calendar.css";
import CalendarDay from "./CalendarDay";
import buildCalendar from "./buildCalendar";
import HijriCalendar from "./hijriCalendar";
import useFetch from "../../hooks/useFetch";
import * as Constans from "../../Constants"

function Calendar() {
  const { loading, error, data } = useFetch("/hijrimonths");
  const [calendar, setCalendar] = useState([]);
  const [value, setValue] = useState(moment());
  const [hijriMonthYear, sethijriMonthYear] = useState({ month: "", year: "" });
  
  useEffect(() => {
    const hijriCalendar = new HijriCalendar(data);
    setCalendar(buildCalendar(value, hijriCalendar, sethijriMonthYear));
  }, [value, data]);

  function isSelected(day) {
    return value.isSame(day, "day");
  }
  function beforeToday(day) {
    return day.isBefore(new Date(), "day");
  }
  function isToday(day) {
    return day.isSame(new Date(), "day");
  }
  function dayStyles(day) {
    if (beforeToday(day) || afterLastDate()) return "before";
    if (isSelected(day)) return "selected";
    if (isToday(day)) return "today";
    return "";
  }
  function currentMonth() {
    return value.format("MMMM");
  }
  function prevMonth() {
    return value.clone().subtract(1, "month");
  }
  function nextMonth() {
    return value.clone().add(1, "month");
  }
  function thisMonth() {
    return value.isSame(new Date(), "month");
  }
  function afterLastDate() {
    return value.isAfter(moment(Constans.GREG_END_DATE, "D/M/YYYY"));
  }
  function handleChange(e) {
    e.target.blur()
    setValue(value.clone().year(e.target.value));
  }
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;
  return (
    <>
      <h1>
        {hijriMonthYear.month} - {hijriMonthYear.year}
      </h1>
      <div className="calendar">
        <div className="calendar-header">
          <div
            className={!thisMonth() ? "previous" : ""}
            onClick={(e) => {
              if (!thisMonth()) {
                setValue(prevMonth());
                e.target.blur();
              }
            }}
          >
            {!thisMonth() ? String.fromCharCode(171) : null}
          </div>
          <div className="current">{currentMonth()}</div>
          <select
            className="year"
            value={value.format("YYYY")}
            onChange={(e) => handleChange(e)}
          >
            <option>{2021}</option>
            <option>{2022}</option>
            <option>{2023}</option>
            <option>{2024}</option>
            <option>{2025}</option>
            <option>{2026}</option>
            {/* <option>{2027}</option>
            <option>{2028}</option>
            <option>{2029}</option>
            <option>{2030}</option>
            <option>{2031}</option> */}
          </select>
          <div
            className="next"
            onClick={(e) => {
              if (!afterLastDate()) {
                setValue(nextMonth());
                e.target.blur();
              }
            }}
          >
            {!afterLastDate() ? String.fromCharCode(187) : null}
          </div>
        </div>
        <div className="calendar-body">
          <div className="day-names">
            {["s", "m", "t", "w", "t", "f", "s"].map((d, idx) => (
              <div key={idx}>{d}</div>
            ))}
          </div>
          {calendar.map((week, widx) => (
            <div key={widx} className="week">
              {week.map((day, didx) => (
                <div
                  key={didx}
                  className={"day " + dayStyles(day[0])}
                  onClick={() => {
                    if (!beforeToday(day[0]) && !afterLastDate()) {
                      setValue(day[0]);
                    }
                  }}
                >
                  <CalendarDay
                    sunDate={day[0].format("D")}
                    moonDate={day[1].getDay()}
                    sunrise={day[2]}
                    sunset={day[3]}
                  />
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Calendar;
