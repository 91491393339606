import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import useFetch from "../hooks/useFetch";
import { useStoreContext } from "../Store";

function ProfileDetail(props) {
  const { setHomePage } = useStoreContext();
  const { id } = useParams();
  const { loading, error, data } = useFetch(`/profiles/${id}`);
  
  useEffect(() => setHomePage(false));
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;
  return (
    <div className="profile-card">
      <div className="circular centre">
        <img
          className="circular"
          src={data.files && data.files[0]}
          alt=""
        />
      </div>
      <div className="profile-card-body">
        <h1>{data.name}</h1>
        <h4>{data.education}</h4>
        <h5>{data.jobTitle}</h5>
        <p>{data.description}</p>
      </div>
      <a href="/">Go back</a>
    </div>
  );
}

export default ProfileDetail;
