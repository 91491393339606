import React, { memo } from "react";
import Calendar from '../components/calendar/Calendar';

function IslamicCalendar(props) {
  return (
    <section
      id="calendar"
    >
      <div className="container pb-40">
        <div className="section-content">
          <div className="row">
            <div className="col-md-7">
              <h3 className="title line-bottom line-height-1 mt-0 mb-30">
                <i className="fa fa-calendar text-theme-colored mr-10"></i>Islamic{" "}
                <span className="text-theme-colored">Calendar</span>
              </h3>
              <div>
                <Calendar />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default memo(IslamicCalendar);
