export const PrayerCalendar =
{
    "0": {
        "1": { "fajr": { "bigin": "03:35", "iqamah": "04:45", "sunrise": "05:14" }, "dohar": { "begin": "12:26", "iqamah": "13:00" }, "asr": { "begin": "16:01", "iqamah": "17:30" }, "maghrib": { "begin": "19:27", "iqamah": "19:30" }, "isha": { "begin": "21:06", "iqamah": "21:15" } },
        "2": { "fajr": { "bigin": "03:36", "iqamah": "04:45", "sunrise": "05:14" }, "dohar": { "begin": "12:26", "iqamah": "13:00" }, "asr": { "begin": "16:01", "iqamah": "17:30" }, "maghrib": { "begin": "19:27", "iqamah": "19:30" }, "isha": { "begin": "21:06", "iqamah": "21:15" } },
        "3": { "fajr": { "bigin": "03:37", "iqamah": "04:45", "sunrise": "05:15" }, "dohar": { "begin": "12:27", "iqamah": "13:00" }, "asr": { "begin": "16:02", "iqamah": "17:30" }, "maghrib": { "begin": "19:27", "iqamah": "19:30" }, "isha": { "begin": "21:06", "iqamah": "21:15" } },
        "4": { "fajr": { "bigin": "03:38", "iqamah": "04:45", "sunrise": "05:16" }, "dohar": { "begin": "12:27", "iqamah": "13:00" }, "asr": { "begin": "16:02", "iqamah": "17:30" }, "maghrib": { "begin": "19:27", "iqamah": "19:30" }, "isha": { "begin": "21:06", "iqamah": "21:15" } },
        "5": { "fajr": { "bigin": "03:39", "iqamah": "04:45", "sunrise": "05:17" }, "dohar": { "begin": "12:27", "iqamah": "13:00" }, "asr": { "begin": "16:03", "iqamah": "17:30" }, "maghrib": { "begin": "19:27", "iqamah": "19:30" }, "isha": { "begin": "21:06", "iqamah": "21:15" } },
        "6": { "fajr": { "bigin": "03:40", "iqamah": "04:45", "sunrise": "05:18" }, "dohar": { "begin": "12:28", "iqamah": "13:00" }, "asr": { "begin": "16:03", "iqamah": "17:30" }, "maghrib": { "begin": "19:27", "iqamah": "19:30" }, "isha": { "begin": "21:05", "iqamah": "21:15" } },
        "7": { "fajr": { "bigin": "03:41", "iqamah": "04:45", "sunrise": "05:18" }, "dohar": { "begin": "12:28", "iqamah": "13:00" }, "asr": { "begin": "16:04", "iqamah": "17:30" }, "maghrib": { "begin": "19:27", "iqamah": "19:30" }, "isha": { "begin": "21:05", "iqamah": "21:15" } },
        "8": { "fajr": { "bigin": "03:42", "iqamah": "04:45", "sunrise": "05:19" }, "dohar": { "begin": "12:29", "iqamah": "13:00" }, "asr": { "begin": "16:04", "iqamah": "17:30" }, "maghrib": { "begin": "19:27", "iqamah": "19:30" }, "isha": { "begin": "21:05", "iqamah": "21:15" } },
        "9": { "fajr": { "bigin": "03:43", "iqamah": "04:45", "sunrise": "05:20" }, "dohar": { "begin": "12:29", "iqamah": "13:00" }, "asr": { "begin": "16:05", "iqamah": "17:30" }, "maghrib": { "begin": "19:27", "iqamah": "19:30" }, "isha": { "begin": "21:05", "iqamah": "21:15" } },
        "10": { "fajr": { "bigin": "03:45", "iqamah": "04:50", "sunrise": "05:21" }, "dohar": { "begin": "12:30", "iqamah": "13:00" }, "asr": { "begin": "16:05", "iqamah": "17:30" }, "maghrib": { "begin": "19:27", "iqamah": "19:30" }, "isha": { "begin": "21:05", "iqamah": "21:15" } },
        "11": { "fajr": { "bigin": "03:46", "iqamah": "04:50", "sunrise": "05:22" }, "dohar": { "begin": "12:30", "iqamah": "13:00" }, "asr": { "begin": "16:05", "iqamah": "17:30" }, "maghrib": { "begin": "19:27", "iqamah": "19:30" }, "isha": { "begin": "21:04", "iqamah": "21:15" } },
        "12": { "fajr": { "bigin": "03:47", "iqamah": "04:50", "sunrise": "05:23" }, "dohar": { "begin": "12:30", "iqamah": "13:00" }, "asr": { "begin": "16:06", "iqamah": "17:30" }, "maghrib": { "begin": "19:27", "iqamah": "19:30" }, "isha": { "begin": "21:04", "iqamah": "21:15" } },
        "13": { "fajr": { "bigin": "03:48", "iqamah": "04:50", "sunrise": "05:24" }, "dohar": { "begin": "12:31", "iqamah": "13:00" }, "asr": { "begin": "16:06", "iqamah": "17:30" }, "maghrib": { "begin": "19:27", "iqamah": "19:30" }, "isha": { "begin": "21:04", "iqamah": "21:15" } },
        "14": { "fajr": { "bigin": "03:49", "iqamah": "04:50", "sunrise": "05:24" }, "dohar": { "begin": "12:31", "iqamah": "13:00" }, "asr": { "begin": "16:07", "iqamah": "17:30" }, "maghrib": { "begin": "19:26", "iqamah": "19:29" }, "isha": { "begin": "21:03", "iqamah": "21:15" } },
        "15": { "fajr": { "bigin": "03:50", "iqamah": "04:50", "sunrise": "05:25" }, "dohar": { "begin": "12:31", "iqamah": "13:00" }, "asr": { "begin": "16:07", "iqamah": "17:30" }, "maghrib": { "begin": "19:26", "iqamah": "19:29" }, "isha": { "begin": "21:03", "iqamah": "21:15" } },
        "16": { "fajr": { "bigin": "03:51", "iqamah": "04:50", "sunrise": "05:26" }, "dohar": { "begin": "12:32", "iqamah": "13:00" }, "asr": { "begin": "16:07", "iqamah": "17:30" }, "maghrib": { "begin": "19:26", "iqamah": "19:29" }, "isha": { "begin": "21:02", "iqamah": "21:15" } },
        "17": { "fajr": { "bigin": "03:53", "iqamah": "04:50", "sunrise": "05:27" }, "dohar": { "begin": "12:32", "iqamah": "13:00" }, "asr": { "begin": "16:08", "iqamah": "17:30" }, "maghrib": { "begin": "19:26", "iqamah": "19:29" }, "isha": { "begin": "21:02", "iqamah": "21:15" } },
        "18": { "fajr": { "bigin": "03:54", "iqamah": "04:50", "sunrise": "05:28" }, "dohar": { "begin": "12:32", "iqamah": "13:00" }, "asr": { "begin": "16:08", "iqamah": "17:30" }, "maghrib": { "begin": "19:26", "iqamah": "19:29" }, "isha": { "begin": "21:01", "iqamah": "21:15" } },
        "19": { "fajr": { "bigin": "03:55", "iqamah": "04:50", "sunrise": "05:29" }, "dohar": { "begin": "12:33", "iqamah": "13:00" }, "asr": { "begin": "16:08", "iqamah": "17:30" }, "maghrib": { "begin": "19:25", "iqamah": "19:28" }, "isha": { "begin": "21:00", "iqamah": "21:15" } },
        "20": { "fajr": { "bigin": "03:56", "iqamah": "04:50", "sunrise": "05:30" }, "dohar": { "begin": "12:33", "iqamah": "13:00" }, "asr": { "begin": "16:09", "iqamah": "17:30" }, "maghrib": { "begin": "19:25", "iqamah": "19:28" }, "isha": { "begin": "21:00", "iqamah": "21:15" } },
        "21": { "fajr": { "bigin": "03:58", "iqamah": "05:00", "sunrise": "05:31" }, "dohar": { "begin": "12:33", "iqamah": "13:00" }, "asr": { "begin": "16:09", "iqamah": "17:30" }, "maghrib": { "begin": "19:25", "iqamah": "19:28" }, "isha": { "begin": "20:59", "iqamah": "21:15" } },
        "22": { "fajr": { "bigin": "03:59", "iqamah": "05:00", "sunrise": "05:32" }, "dohar": { "begin": "12:34", "iqamah": "13:00" }, "asr": { "begin": "16:09", "iqamah": "17:30" }, "maghrib": { "begin": "19:24", "iqamah": "19:27" }, "isha": { "begin": "20:59", "iqamah": "21:15" } },
        "23": { "fajr": { "bigin": "04:00", "iqamah": "05:00", "sunrise": "05:33" }, "dohar": { "begin": "12:34", "iqamah": "13:00" }, "asr": { "begin": "16:09", "iqamah": "17:30" }, "maghrib": { "begin": "19:24", "iqamah": "19:27" }, "isha": { "begin": "20:58", "iqamah": "21:15" } },
        "24": { "fajr": { "bigin": "04:01", "iqamah": "05:00", "sunrise": "05:34" }, "dohar": { "begin": "12:34", "iqamah": "13:00" }, "asr": { "begin": "16:10", "iqamah": "17:30" }, "maghrib": { "begin": "19:23", "iqamah": "19:26" }, "isha": { "begin": "20:57", "iqamah": "21:15" } },
        "25": { "fajr": { "bigin": "04:03", "iqamah": "05:00", "sunrise": "05:35" }, "dohar": { "begin": "12:34", "iqamah": "13:00" }, "asr": { "begin": "16:10", "iqamah": "17:30" }, "maghrib": { "begin": "19:23", "iqamah": "19:26" }, "isha": { "begin": "20:56", "iqamah": "21:15" } },
        "26": { "fajr": { "bigin": "04:04", "iqamah": "05:00", "sunrise": "05:36" }, "dohar": { "begin": "12:35", "iqamah": "13:00" }, "asr": { "begin": "16:10", "iqamah": "17:30" }, "maghrib": { "begin": "19:22", "iqamah": "19:25" }, "isha": { "begin": "20:55", "iqamah": "21:10" } },
        "27": { "fajr": { "bigin": "04:05", "iqamah": "05:00", "sunrise": "05:36" }, "dohar": { "begin": "12:35", "iqamah": "13:00" }, "asr": { "begin": "16:10", "iqamah": "17:30" }, "maghrib": { "begin": "19:22", "iqamah": "19:25" }, "isha": { "begin": "20:55", "iqamah": "21:10" } },
        "28": { "fajr": { "bigin": "04:06", "iqamah": "05:00", "sunrise": "05:37" }, "dohar": { "begin": "12:35", "iqamah": "13:00" }, "asr": { "begin": "16:10", "iqamah": "17:30" }, "maghrib": { "begin": "19:21", "iqamah": "19:24" }, "isha": { "begin": "20:54", "iqamah": "21:10" } },
        "29": { "fajr": { "bigin": "04:08", "iqamah": "05:00", "sunrise": "05:38" }, "dohar": { "begin": "12:35", "iqamah": "13:00" }, "asr": { "begin": "16:11", "iqamah": "17:30" }, "maghrib": { "begin": "19:21", "iqamah": "19:24" }, "isha": { "begin": "20:53", "iqamah": "21:10" } },
        "30": { "fajr": { "bigin": "04:09", "iqamah": "05:00", "sunrise": "05:39" }, "dohar": { "begin": "12:35", "iqamah": "13:00" }, "asr": { "begin": "16:11", "iqamah": "17:30" }, "maghrib": { "begin": "19:20", "iqamah": "19:23" }, "isha": { "begin": "20:52", "iqamah": "21:10" } },
        "31": { "fajr": { "bigin": "04:10", "iqamah": "05:00", "sunrise": "05:40" }, "dohar": { "begin": "12:36", "iqamah": "13:00" }, "asr": { "begin": "16:11", "iqamah": "17:30" }, "maghrib": { "begin": "19:20", "iqamah": "19:23" }, "isha": { "begin": "20:51", "iqamah": "21:10" } },
    },
    "1": {
        "1": { "fajr": { "begin": "04:11", "iqamah": "05:10", "sunrise": "05:41" }, "dohar": { "bigin": "12:36", "iqamah": "13:00" }, "asr": { "begin": "16:11", "iqamah": "17:30" }, "maghrib": { "begin": "19:19", "iqamah": "19:22" }, "isha": { "begin": "20:50", "iqamah": "21:00" } },
        "2": { "fajr": { "begin": "04:13", "iqamah": "05:10", "sunrise": "05:42" }, "dohar": { "bigin": "12:36", "iqamah": "13:00" }, "asr": { "begin": "16:11", "iqamah": "17:30" }, "maghrib": { "begin": "19:18", "iqamah": "19:21" }, "isha": { "begin": "20:49", "iqamah": "21:00" } },
        "3": { "fajr": { "begin": "04:14", "iqamah": "05:10", "sunrise": "05:43" }, "dohar": { "bigin": "12:36", "iqamah": "13:00" }, "asr": { "begin": "16:11", "iqamah": "17:30" }, "maghrib": { "begin": "19:18", "iqamah": "19:21" }, "isha": { "begin": "20:48", "iqamah": "21:00" } },
        "4": { "fajr": { "begin": "04:15", "iqamah": "05:10", "sunrise": "05:44" }, "dohar": { "bigin": "12:36", "iqamah": "13:00" }, "asr": { "begin": "16:11", "iqamah": "17:30" }, "maghrib": { "begin": "19:17", "iqamah": "19:20" }, "isha": { "begin": "20:47", "iqamah": "21:00" } },
        "5": { "fajr": { "begin": "04:16", "iqamah": "05:10", "sunrise": "05:45" }, "dohar": { "bigin": "12:36", "iqamah": "13:00" }, "asr": { "begin": "16:11", "iqamah": "17:30" }, "maghrib": { "begin": "19:16", "iqamah": "19:19" }, "isha": { "begin": "20:46", "iqamah": "21:00" } },
        "6": { "fajr": { "begin": "04:18", "iqamah": "05:10", "sunrise": "05:46" }, "dohar": { "bigin": "12:36", "iqamah": "13:00" }, "asr": { "begin": "16:11", "iqamah": "17:30" }, "maghrib": { "begin": "19:15", "iqamah": "19:18" }, "isha": { "begin": "20:45", "iqamah": "21:00" } },
        "7": { "fajr": { "begin": "04:19", "iqamah": "05:10", "sunrise": "05:47" }, "dohar": { "bigin": "12:36", "iqamah": "13:00" }, "asr": { "begin": "16:11", "iqamah": "17:30" }, "maghrib": { "begin": "19:15", "iqamah": "19:18" }, "isha": { "begin": "20:44", "iqamah": "21:00" } },
        "8": { "fajr": { "begin": "04:20", "iqamah": "05:10", "sunrise": "05:48" }, "dohar": { "bigin": "12:36", "iqamah": "13:00" }, "asr": { "begin": "16:11", "iqamah": "17:30" }, "maghrib": { "begin": "19:14", "iqamah": "19:17" }, "isha": { "begin": "20:43", "iqamah": "21:00" } },
        "9": { "fajr": { "begin": "04:21", "iqamah": "05:10", "sunrise": "05:49" }, "dohar": { "bigin": "12:36", "iqamah": "13:00" }, "asr": { "begin": "16:11", "iqamah": "17:30" }, "maghrib": { "begin": "19:13", "iqamah": "19:16" }, "isha": { "begin": "20:42", "iqamah": "21:00" } },
        "10": { "fajr": { "begin": "04:22", "iqamah": "05:10", "sunrise": "05:49" }, "dohar": { "bigin": "12:36", "iqamah": "13:00" }, "asr": { "begin": "16:11", "iqamah": "17:30" }, "maghrib": { "begin": "19:12", "iqamah": "19:15" }, "isha": { "begin": "20:40", "iqamah": "21:00" } },
        "11": { "fajr": { "begin": "04:24", "iqamah": "05:20", "sunrise": "05:50" }, "dohar": { "bigin": "12:36", "iqamah": "13:00" }, "asr": { "begin": "16:10", "iqamah": "17:30" }, "maghrib": { "begin": "19:11", "iqamah": "19:14" }, "isha": { "begin": "20:39", "iqamah": "20:50" } },
        "12": { "fajr": { "begin": "04:25", "iqamah": "05:20", "sunrise": "05:51" }, "dohar": { "bigin": "12:36", "iqamah": "13:00" }, "asr": { "begin": "16:10", "iqamah": "17:30" }, "maghrib": { "begin": "19:10", "iqamah": "19:13" }, "isha": { "begin": "20:38", "iqamah": "20:50" } },
        "13": { "fajr": { "begin": "04:26", "iqamah": "05:20", "sunrise": "05:52" }, "dohar": { "bigin": "12:36", "iqamah": "13:00" }, "asr": { "begin": "16:10", "iqamah": "17:30" }, "maghrib": { "begin": "19:09", "iqamah": "19:12" }, "isha": { "begin": "20:37", "iqamah": "20:50" } },
        "14": { "fajr": { "begin": "04:27", "iqamah": "05:20", "sunrise": "05:53" }, "dohar": { "bigin": "12:36", "iqamah": "13:00" }, "asr": { "begin": "16:10", "iqamah": "17:30" }, "maghrib": { "begin": "19:08", "iqamah": "19:11" }, "isha": { "begin": "20:36", "iqamah": "20:50" } },
        "15": { "fajr": { "begin": "04:28", "iqamah": "05:20", "sunrise": "05:54" }, "dohar": { "bigin": "12:36", "iqamah": "13:00" }, "asr": { "begin": "16:10", "iqamah": "17:30" }, "maghrib": { "begin": "19:07", "iqamah": "19:10" }, "isha": { "begin": "20:34", "iqamah": "20:50" } },
        "16": { "fajr": { "begin": "04:29", "iqamah": "05:20", "sunrise": "05:55" }, "dohar": { "bigin": "12:36", "iqamah": "13:00" }, "asr": { "begin": "16:09", "iqamah": "17:30" }, "maghrib": { "begin": "19:06", "iqamah": "19:09" }, "isha": { "begin": "20:33", "iqamah": "20:50" } },
        "17": { "fajr": { "begin": "04:30", "iqamah": "05:20", "sunrise": "05:56" }, "dohar": { "bigin": "12:36", "iqamah": "13:00" }, "asr": { "begin": "16:09", "iqamah": "17:30" }, "maghrib": { "begin": "19:05", "iqamah": "19:08" }, "isha": { "begin": "20:32", "iqamah": "20:50" } },
        "18": { "fajr": { "begin": "04:32", "iqamah": "05:20", "sunrise": "05:56" }, "dohar": { "bigin": "12:36", "iqamah": "13:00" }, "asr": { "begin": "16:09", "iqamah": "17:30" }, "maghrib": { "begin": "19:04", "iqamah": "19:07" }, "isha": { "begin": "20:31", "iqamah": "20:50" } },
        "19": { "fajr": { "begin": "04:33", "iqamah": "05:20", "sunrise": "05:57" }, "dohar": { "bigin": "12:36", "iqamah": "13:00" }, "asr": { "begin": "16:09", "iqamah": "17:30" }, "maghrib": { "begin": "19:03", "iqamah": "19:06" }, "isha": { "begin": "20:29", "iqamah": "20:50" } },
        "20": { "fajr": { "begin": "04:34", "iqamah": "05:20", "sunrise": "05:58" }, "dohar": { "bigin": "12:36", "iqamah": "13:00" }, "asr": { "begin": "16:08", "iqamah": "17:30" }, "maghrib": { "begin": "19:02", "iqamah": "19:05" }, "isha": { "begin": "20:28", "iqamah": "20:50" } },
        "21": { "fajr": { "begin": "04:35", "iqamah": "05:30", "sunrise": "05:59" }, "dohar": { "bigin": "12:36", "iqamah": "13:00" }, "asr": { "begin": "16:08", "iqamah": "17:30" }, "maghrib": { "begin": "19:01", "iqamah": "19:04" }, "isha": { "begin": "20:27", "iqamah": "20:40" } },
        "22": { "fajr": { "begin": "04:36", "iqamah": "05:30", "sunrise": "06:00" }, "dohar": { "bigin": "12:36", "iqamah": "13:00" }, "asr": { "begin": "16:08", "iqamah": "17:30" }, "maghrib": { "begin": "19:00", "iqamah": "19:03" }, "isha": { "begin": "20:26", "iqamah": "20:40" } },
        "23": { "fajr": { "begin": "04:37", "iqamah": "05:30", "sunrise": "06:01" }, "dohar": { "bigin": "12:36", "iqamah": "13:00" }, "asr": { "begin": "16:07", "iqamah": "17:30" }, "maghrib": { "begin": "18:59", "iqamah": "19:02" }, "isha": { "begin": "20:24", "iqamah": "20:40" } },
        "24": { "fajr": { "begin": "04:38", "iqamah": "05:30", "sunrise": "06:01" }, "dohar": { "bigin": "12:35", "iqamah": "13:00" }, "asr": { "begin": "16:07", "iqamah": "17:30" }, "maghrib": { "begin": "18:58", "iqamah": "19:01" }, "isha": { "begin": "20:23", "iqamah": "20:40" } },
        "25": { "fajr": { "begin": "04:39", "iqamah": "05:30", "sunrise": "06:02" }, "dohar": { "bigin": "12:35", "iqamah": "13:00" }, "asr": { "begin": "16:06", "iqamah": "17:30" }, "maghrib": { "begin": "18:57", "iqamah": "19:00" }, "isha": { "begin": "20:22", "iqamah": "20:40" } },
        "26": { "fajr": { "begin": "04:40", "iqamah": "05:30", "sunrise": "06:03" }, "dohar": { "bigin": "12:35", "iqamah": "13:00" }, "asr": { "begin": "16:06", "iqamah": "17:30" }, "maghrib": { "begin": "18:56", "iqamah": "18:59" }, "isha": { "begin": "20:20", "iqamah": "20:40" } },
        "27": { "fajr": { "begin": "04:41", "iqamah": "05:30", "sunrise": "06:04" }, "dohar": { "bigin": "12:35", "iqamah": "13:00" }, "asr": { "begin": "16:05", "iqamah": "17:30" }, "maghrib": { "begin": "18:55", "iqamah": "18:58" }, "isha": { "begin": "20:19", "iqamah": "20:40" } },
        "28": { "fajr": { "begin": "04:42", "iqamah": "05:30", "sunrise": "06:05" }, "dohar": { "bigin": "12:35", "iqamah": "13:00" }, "asr": { "begin": "16:05", "iqamah": "17:30" }, "maghrib": { "begin": "18:54", "iqamah": "18:57" }, "isha": { "begin": "20:18", "iqamah": "20:40" } },
        "29": { "fajr": { "begin": "04:42", "iqamah": "05:30", "sunrise": "06:05" }, "dohar": { "bigin": "12:35", "iqamah": "13:00" }, "asr": { "begin": "16:05", "iqamah": "17:30" }, "maghrib": { "begin": "18:53", "iqamah": "18:56" }, "isha": { "begin": "20:17", "iqamah": "20:40" } },
    },
    "2": {
        "1": { "fajr": { "bigin": "04:43", "iqamah": "05:30", "sunrise": "06:05" }, "dohar": { "bigin": "12:35", "iqamah": "13:00" }, "asr": { "bigin": "16:05", "iqamah": "17:30" }, "maghrib": { "bigin": "18:52", "iqamah": "18:55" }, "isha": { "bigin": "20:16", "iqamah": "20:30" } },
        "2": { "fajr": { "bigin": "04:44", "iqamah": "05:30", "sunrise": "06:06" }, "dohar": { "bigin": "12:34", "iqamah": "13:00" }, "asr": { "bigin": "16:04", "iqamah": "17:30" }, "maghrib": { "bigin": "18:51", "iqamah": "18:54" }, "isha": { "bigin": "20:15", "iqamah": "20:30" } },
        "3": { "fajr": { "bigin": "04:45", "iqamah": "05:30", "sunrise": "06:07" }, "dohar": { "bigin": "12:34", "iqamah": "13:00" }, "asr": { "bigin": "16:03", "iqamah": "17:30" }, "maghrib": { "bigin": "18:50", "iqamah": "18:53" }, "isha": { "bigin": "20:13", "iqamah": "20:30" } },
        "4": { "fajr": { "bigin": "04:46", "iqamah": "05:30", "sunrise": "06:08" }, "dohar": { "bigin": "12:34", "iqamah": "13:00" }, "asr": { "bigin": "16:03", "iqamah": "17:30" }, "maghrib": { "bigin": "18:49", "iqamah": "18:52" }, "isha": { "bigin": "20:12", "iqamah": "20:30" } },
        "5": { "fajr": { "bigin": "04:47", "iqamah": "05:30", "sunrise": "06:09" }, "dohar": { "bigin": "12:34", "iqamah": "13:00" }, "asr": { "bigin": "16:02", "iqamah": "17:30" }, "maghrib": { "bigin": "18:48", "iqamah": "18:51" }, "isha": { "bigin": "20:11", "iqamah": "20:30" } },
        "6": { "fajr": { "bigin": "04:48", "iqamah": "05:30", "sunrise": "06:09" }, "dohar": { "bigin": "12:34", "iqamah": "13:00" }, "asr": { "bigin": "16:02", "iqamah": "17:30" }, "maghrib": { "bigin": "18:46", "iqamah": "18:49" }, "isha": { "bigin": "20:09", "iqamah": "20:30" } },
        "7": { "fajr": { "bigin": "04:49", "iqamah": "05:40", "sunrise": "06:10" }, "dohar": { "bigin": "12:33", "iqamah": "13:00" }, "asr": { "bigin": "16:01", "iqamah": "17:30" }, "maghrib": { "bigin": "18:45", "iqamah": "18:48" }, "isha": { "bigin": "20:08", "iqamah": "20:30" } },
        "8": { "fajr": { "bigin": "04:49", "iqamah": "05:40", "sunrise": "06:11" }, "dohar": { "bigin": "12:33", "iqamah": "13:00" }, "asr": { "bigin": "16:01", "iqamah": "17:30" }, "maghrib": { "bigin": "18:44", "iqamah": "18:47" }, "isha": { "bigin": "20:07", "iqamah": "20:30" } },
        "9": { "fajr": { "bigin": "04:50", "iqamah": "05:40", "sunrise": "06:11" }, "dohar": { "bigin": "12:33", "iqamah": "13:00" }, "asr": { "bigin": "16:00", "iqamah": "17:30" }, "maghrib": { "bigin": "18:43", "iqamah": "18:46" }, "isha": { "bigin": "20:05", "iqamah": "20:30" } },
        "10": { "fajr": { "bigin": "04:51", "iqamah": "05:40", "sunrise": "06:12" }, "dohar": { "bigin": "12:33", "iqamah": "13:00" }, "asr": { "bigin": "15:59", "iqamah": "17:30" }, "maghrib": { "bigin": "18:42", "iqamah": "18:45" }, "isha": { "bigin": "20:04", "iqamah": "20:30" } },
        "11": { "fajr": { "bigin": "04:52", "iqamah": "05:40", "sunrise": "06:13" }, "dohar": { "bigin": "12:32", "iqamah": "13:00" }, "asr": { "bigin": "15:59", "iqamah": "17:30" }, "maghrib": { "bigin": "18:40", "iqamah": "18:43" }, "isha": { "bigin": "20:03", "iqamah": "20:20" } },
        "12": { "fajr": { "bigin": "04:53", "iqamah": "05:40", "sunrise": "06:14" }, "dohar": { "bigin": "12:32", "iqamah": "13:00" }, "asr": { "bigin": "15:58", "iqamah": "17:30" }, "maghrib": { "bigin": "18:39", "iqamah": "18:42" }, "isha": { "bigin": "20:01", "iqamah": "20:20" } },
        "13": { "fajr": { "bigin": "04:54", "iqamah": "05:40", "sunrise": "06:14" }, "dohar": { "bigin": "12:32", "iqamah": "13:00" }, "asr": { "bigin": "15:57", "iqamah": "17:30" }, "maghrib": { "bigin": "18:38", "iqamah": "18:41" }, "isha": { "bigin": "20:00", "iqamah": "20:20" } },
        "14": { "fajr": { "bigin": "04:55", "iqamah": "05:40", "sunrise": "06:15" }, "dohar": { "bigin": "12:31", "iqamah": "13:00" }, "asr": { "bigin": "15:57", "iqamah": "17:30" }, "maghrib": { "bigin": "18:37", "iqamah": "18:40" }, "isha": { "bigin": "19:58", "iqamah": "20:20" } },
        "15": { "fajr": { "bigin": "04:55", "iqamah": "05:40", "sunrise": "06:16" }, "dohar": { "bigin": "12:31", "iqamah": "13:00" }, "asr": { "bigin": "15:56", "iqamah": "17:15" }, "maghrib": { "bigin": "18:35", "iqamah": "18:38" }, "isha": { "bigin": "19:57", "iqamah": "20:20" } },
        "16": { "fajr": { "bigin": "04:56", "iqamah": "05:40", "sunrise": "06:17" }, "dohar": { "bigin": "12:31", "iqamah": "13:00" }, "asr": { "bigin": "15:55", "iqamah": "17:15" }, "maghrib": { "bigin": "18:34", "iqamah": "18:37" }, "isha": { "bigin": "19:56", "iqamah": "20:20" } },
        "17": { "fajr": { "bigin": "04:57", "iqamah": "05:40", "sunrise": "06:17" }, "dohar": { "bigin": "12:31", "iqamah": "13:00" }, "asr": { "bigin": "15:54", "iqamah": "17:15" }, "maghrib": { "bigin": "18:33", "iqamah": "18:36" }, "isha": { "bigin": "19:54", "iqamah": "20:20" } },
        "18": { "fajr": { "bigin": "04:58", "iqamah": "05:40", "sunrise": "06:18" }, "dohar": { "bigin": "12:30", "iqamah": "13:00" }, "asr": { "bigin": "15:54", "iqamah": "17:15" }, "maghrib": { "bigin": "18:31", "iqamah": "18:34" }, "isha": { "bigin": "19:53", "iqamah": "20:20" } },
        "19": { "fajr": { "bigin": "04:59", "iqamah": "05:40", "sunrise": "06:19" }, "dohar": { "bigin": "12:30", "iqamah": "13:00" }, "asr": { "bigin": "15:53", "iqamah": "17:15" }, "maghrib": { "bigin": "18:30", "iqamah": "18:33" }, "isha": { "bigin": "19:52", "iqamah": "20:20" } },
        "20": { "fajr": { "bigin": "04:59", "iqamah": "05:40", "sunrise": "06:19" }, "dohar": { "bigin": "12:30", "iqamah": "13:00" }, "asr": { "bigin": "15:52", "iqamah": "17:15" }, "maghrib": { "bigin": "18:29", "iqamah": "18:32" }, "isha": { "bigin": "19:50", "iqamah": "20:20" } },
        "21": { "fajr": { "bigin": "05:00", "iqamah": "05:50", "sunrise": "06:20" }, "dohar": { "bigin": "12:29", "iqamah": "13:00" }, "asr": { "bigin": "15:51", "iqamah": "17:15" }, "maghrib": { "bigin": "18:28", "iqamah": "18:31" }, "isha": { "bigin": "19:49", "iqamah": "20:10" } },
        "22": { "fajr": { "bigin": "05:01", "iqamah": "05:50", "sunrise": "06:21" }, "dohar": { "bigin": "12:29", "iqamah": "13:00" }, "asr": { "bigin": "15:50", "iqamah": "17:15" }, "maghrib": { "bigin": "18:26", "iqamah": "18:29" }, "isha": { "bigin": "19:47", "iqamah": "20:10" } },
        "23": { "fajr": { "bigin": "05:02", "iqamah": "05:50", "sunrise": "06:21" }, "dohar": { "bigin": "12:29", "iqamah": "13:00" }, "asr": { "bigin": "15:50", "iqamah": "17:15" }, "maghrib": { "bigin": "18:25", "iqamah": "18:28" }, "isha": { "bigin": "19:46", "iqamah": "20:10" } },
        "24": { "fajr": { "bigin": "05:02", "iqamah": "05:50", "sunrise": "06:22" }, "dohar": { "bigin": "12:29", "iqamah": "13:00" }, "asr": { "bigin": "15:49", "iqamah": "17:15" }, "maghrib": { "bigin": "18:24", "iqamah": "18:27" }, "isha": { "bigin": "19:45", "iqamah": "20:10" } },
        "25": { "fajr": { "bigin": "05:03", "iqamah": "05:50", "sunrise": "06:23" }, "dohar": { "bigin": "12:28", "iqamah": "13:00" }, "asr": { "bigin": "15:48", "iqamah": "17:15" }, "maghrib": { "bigin": "18:22", "iqamah": "18:25" }, "isha": { "bigin": "19:43", "iqamah": "20:10" } },
        "26": { "fajr": { "bigin": "05:04", "iqamah": "05:50", "sunrise": "06:23" }, "dohar": { "bigin": "12:28", "iqamah": "13:00" }, "asr": { "bigin": "15:47", "iqamah": "17:15" }, "maghrib": { "bigin": "18:21", "iqamah": "18:24" }, "isha": { "bigin": "19:42", "iqamah": "20:10" } },
        "27": { "fajr": { "bigin": "05:04", "iqamah": "05:50", "sunrise": "06:24" }, "dohar": { "bigin": "12:28", "iqamah": "13:00" }, "asr": { "bigin": "15:46", "iqamah": "17:15" }, "maghrib": { "bigin": "18:20", "iqamah": "18:23" }, "isha": { "bigin": "19:41", "iqamah": "20:10" } },
        "28": { "fajr": { "bigin": "05:05", "iqamah": "05:50", "sunrise": "06:25" }, "dohar": { "bigin": "12:27", "iqamah": "13:00" }, "asr": { "bigin": "15:46", "iqamah": "17:15" }, "maghrib": { "bigin": "18:19", "iqamah": "18:22" }, "isha": { "bigin": "19:40", "iqamah": "20:10" } },
        "29": { "fajr": { "bigin": "05:06", "iqamah": "05:50", "sunrise": "06:25" }, "dohar": { "bigin": "12:27", "iqamah": "13:00" }, "asr": { "bigin": "15:45", "iqamah": "17:15" }, "maghrib": { "bigin": "18:17", "iqamah": "18:20" }, "isha": { "bigin": "19:38", "iqamah": "20:10" } },
        "30": { "fajr": { "bigin": "05:07", "iqamah": "05:50", "sunrise": "06:26" }, "dohar": { "bigin": "12:27", "iqamah": "13:00" }, "asr": { "bigin": "15:44", "iqamah": "17:15" }, "maghrib": { "bigin": "18:16", "iqamah": "18:19" }, "isha": { "bigin": "19:37", "iqamah": "20:10" } },
        "31": { "fajr": { "bigin": "05:07", "iqamah": "05:50", "sunrise": "06:27" }, "dohar": { "bigin": "12:26", "iqamah": "13:00" }, "asr": { "bigin": "15:43", "iqamah": "17:15" }, "maghrib": { "bigin": "18:15", "iqamah": "18:19" }, "isha": { "bigin": "19:36", "iqamah": "20:10" } },
    },
    "3": {
        "1": { "fajr": { "bigin": "05:08", "iqamah": "06:00", "sunrise": "06:27" }, "dohar": { "bigin": "12:26", "iqamah": "13:00" }, "asr": { "bigin": "15:42", "iqamah": "16:45" }, "maghrib": { "bigin": "18:14", "iqamah": "18:17" }, "isha": { "bigin": "19:34", "iqamah": "20:00" } },
        "2": { "fajr": { "bigin": "05:09", "iqamah": "06:00", "sunrise": "06:28" }, "dohar": { "bigin": "12:26", "iqamah": "13:00" }, "asr": { "bigin": "15:41", "iqamah": "16:45" }, "maghrib": { "bigin": "18:12", "iqamah": "18:15" }, "isha": { "bigin": "19:33", "iqamah": "20:00" } },
        "3": { "fajr": { "bigin": "05:09", "iqamah": "06:00", "sunrise": "06:29" }, "dohar": { "bigin": "12:26", "iqamah": "13:00" }, "asr": { "bigin": "15:40", "iqamah": "16:45" }, "maghrib": { "bigin": "18:11", "iqamah": "18:14" }, "isha": { "bigin": "19:32", "iqamah": "20:00" } },
        "4": { "fajr": { "bigin": "05:10", "iqamah": "06:00", "sunrise": "06:29" }, "dohar": { "bigin": "12:25", "iqamah": "13:00" }, "asr": { "bigin": "15:40", "iqamah": "16:45" }, "maghrib": { "bigin": "18:10", "iqamah": "18:13" }, "isha": { "bigin": "19:31", "iqamah": "20:00" } },
        "5": { "fajr": { "bigin": "05:11", "iqamah": "06:00", "sunrise": "06:30" }, "dohar": { "bigin": "12:25", "iqamah": "13:00" }, "asr": { "bigin": "15:39", "iqamah": "16:45" }, "maghrib": { "bigin": "18:09", "iqamah": "18:12" }, "isha": { "bigin": "19:29", "iqamah": "20:00" } },
        "6": { "fajr": { "bigin": "05:11", "iqamah": "06:00", "sunrise": "06:31" }, "dohar": { "bigin": "12:25", "iqamah": "13:00" }, "asr": { "bigin": "15:38", "iqamah": "16:45" }, "maghrib": { "bigin": "18:07", "iqamah": "18:10" }, "isha": { "bigin": "19:28", "iqamah": "20:00" } },
        "7": { "fajr": { "bigin": "05:12", "iqamah": "06:00", "sunrise": "06:31" }, "dohar": { "bigin": "12:24", "iqamah": "13:00" }, "asr": { "bigin": "15:37", "iqamah": "16:45" }, "maghrib": { "bigin": "18:06", "iqamah": "18:09" }, "isha": { "bigin": "19:27", "iqamah": "20:00" } },
        "8": { "fajr": { "bigin": "05:12", "iqamah": "06:00", "sunrise": "06:32" }, "dohar": { "bigin": "12:24", "iqamah": "13:00" }, "asr": { "bigin": "15:36", "iqamah": "16:45" }, "maghrib": { "bigin": "18:05", "iqamah": "18:08" }, "isha": { "bigin": "19:26", "iqamah": "20:00" } },
        "9": { "fajr": { "bigin": "05:13", "iqamah": "06:00", "sunrise": "06:33" }, "dohar": { "bigin": "12:24", "iqamah": "13:00" }, "asr": { "bigin": "15:35", "iqamah": "16:45" }, "maghrib": { "bigin": "18:04", "iqamah": "18:07" }, "isha": { "bigin": "19:25", "iqamah": "20:00" } },
        "10": { "fajr": { "bigin": "05:14", "iqamah": "06:00", "sunrise": "06:33" }, "dohar": { "bigin": "12:24", "iqamah": "13:00" }, "asr": { "bigin": "15:34", "iqamah": "16:45" }, "maghrib": { "bigin": "18:02", "iqamah": "18:05" }, "isha": { "bigin": "19:23", "iqamah": "20:00" } },
        "11": { "fajr": { "bigin": "05:14", "iqamah": "06:00", "sunrise": "06:34" }, "dohar": { "bigin": "12:23", "iqamah": "13:00" }, "asr": { "bigin": "15:33", "iqamah": "16:45" }, "maghrib": { "bigin": "18:01", "iqamah": "18:04" }, "isha": { "bigin": "19:22", "iqamah": "19:50" } },
        "12": { "fajr": { "bigin": "05:15", "iqamah": "06:00", "sunrise": "06:35" }, "dohar": { "bigin": "12:23", "iqamah": "13:00" }, "asr": { "bigin": "15:33", "iqamah": "16:45" }, "maghrib": { "bigin": "18:00", "iqamah": "18:03" }, "isha": { "bigin": "19:21", "iqamah": "19:50" } },
        "13": { "fajr": { "bigin": "05:16", "iqamah": "06:00", "sunrise": "06:35" }, "dohar": { "bigin": "12:23", "iqamah": "13:00" }, "asr": { "bigin": "15:32", "iqamah": "16:45" }, "maghrib": { "bigin": "17:59", "iqamah": "18:02" }, "isha": { "bigin": "19:20", "iqamah": "19:50" } },
        "14": { "fajr": { "bigin": "05:16", "iqamah": "06:00", "sunrise": "06:36" }, "dohar": { "bigin": "12:23", "iqamah": "13:00" }, "asr": { "bigin": "15:31", "iqamah": "16:45" }, "maghrib": { "bigin": "17:58", "iqamah": "18:01" }, "isha": { "bigin": "19:19", "iqamah": "19:50" } },
        "15": { "fajr": { "bigin": "05:17", "iqamah": "06:00", "sunrise": "06:37" }, "dohar": { "bigin": "12:22", "iqamah": "13:00" }, "asr": { "bigin": "15:30", "iqamah": "16:45" }, "maghrib": { "bigin": "17:56", "iqamah": "17:59" }, "isha": { "bigin": "19:18", "iqamah": "19:50" } },
        "16": { "fajr": { "bigin": "05:17", "iqamah": "06:10", "sunrise": "06:38" }, "dohar": { "bigin": "12:22", "iqamah": "13:00" }, "asr": { "bigin": "15:29", "iqamah": "16:30" }, "maghrib": { "bigin": "17:55", "iqamah": "17:58" }, "isha": { "bigin": "19:17", "iqamah": "19:50" } },
        "17": { "fajr": { "bigin": "05:18", "iqamah": "06:10", "sunrise": "06:38" }, "dohar": { "bigin": "12:22", "iqamah": "13:00" }, "asr": { "bigin": "15:28", "iqamah": "16:30" }, "maghrib": { "bigin": "17:54", "iqamah": "17:57" }, "isha": { "bigin": "19:16", "iqamah": "19:50" } },
        "18": { "fajr": { "bigin": "05:19", "iqamah": "06:10", "sunrise": "06:39" }, "dohar": { "bigin": "12:22", "iqamah": "13:00" }, "asr": { "bigin": "15:27", "iqamah": "16:30" }, "maghrib": { "bigin": "17:53", "iqamah": "17:56" }, "isha": { "bigin": "19:15", "iqamah": "19:50" } },
        "19": { "fajr": { "bigin": "05:19", "iqamah": "06:10", "sunrise": "06:40" }, "dohar": { "bigin": "12:21", "iqamah": "13:00" }, "asr": { "bigin": "15:27", "iqamah": "16:30" }, "maghrib": { "bigin": "17:52", "iqamah": "17:55" }, "isha": { "bigin": "19:14", "iqamah": "19:50" } },
        "20": { "fajr": { "bigin": "05:20", "iqamah": "06:10", "sunrise": "06:40" }, "dohar": { "bigin": "12:21", "iqamah": "13:00" }, "asr": { "bigin": "15:26", "iqamah": "16:30" }, "maghrib": { "bigin": "17:51", "iqamah": "17:54" }, "isha": { "bigin": "19:12", "iqamah": "19:50" } },
        "21": { "fajr": { "bigin": "05:20", "iqamah": "06:10", "sunrise": "06:41" }, "dohar": { "bigin": "12:21", "iqamah": "13:00" }, "asr": { "bigin": "15:25", "iqamah": "16:30" }, "maghrib": { "bigin": "17:50", "iqamah": "17:53" }, "isha": { "bigin": "19:11", "iqamah": "19:40" } },
        "22": { "fajr": { "bigin": "05:21", "iqamah": "06:10", "sunrise": "06:42" }, "dohar": { "bigin": "12:21", "iqamah": "13:00" }, "asr": { "bigin": "15:24", "iqamah": "16:30" }, "maghrib": { "bigin": "17:49", "iqamah": "17:52" }, "isha": { "bigin": "19:10", "iqamah": "19:40" } },
        "23": { "fajr": { "bigin": "05:22", "iqamah": "06:10", "sunrise": "06:42" }, "dohar": { "bigin": "12:21", "iqamah": "13:00" }, "asr": { "bigin": "15:23", "iqamah": "16:30" }, "maghrib": { "bigin": "17:48", "iqamah": "17:51" }, "isha": { "bigin": "19:10", "iqamah": "19:40" } },
        "24": { "fajr": { "bigin": "05:22", "iqamah": "06:10", "sunrise": "06:43" }, "dohar": { "bigin": "12:20", "iqamah": "13:00" }, "asr": { "bigin": "15:22", "iqamah": "16:30" }, "maghrib": { "bigin": "17:47", "iqamah": "17:50" }, "isha": { "bigin": "19:09", "iqamah": "19:40" } },
        "25": { "fajr": { "bigin": "05:23", "iqamah": "06:10", "sunrise": "06:44" }, "dohar": { "bigin": "12:20", "iqamah": "13:00" }, "asr": { "bigin": "15:22", "iqamah": "16:30" }, "maghrib": { "bigin": "17:46", "iqamah": "17:49" }, "isha": { "bigin": "19:08", "iqamah": "19:40" } },
        "26": { "fajr": { "bigin": "05:23", "iqamah": "06:10", "sunrise": "06:44" }, "dohar": { "bigin": "12:20", "iqamah": "13:00" }, "asr": { "bigin": "15:21", "iqamah": "16:30" }, "maghrib": { "bigin": "17:45", "iqamah": "17:48" }, "isha": { "bigin": "19:07", "iqamah": "19:40" } },
        "27": { "fajr": { "bigin": "05:24", "iqamah": "06:10", "sunrise": "06:45" }, "dohar": { "bigin": "12:20", "iqamah": "13:00" }, "asr": { "bigin": "15:20", "iqamah": "16:30" }, "maghrib": { "bigin": "17:44", "iqamah": "17:47" }, "isha": { "bigin": "19:06", "iqamah": "19:40" } },
        "28": { "fajr": { "bigin": "05:25", "iqamah": "06:10", "sunrise": "06:46" }, "dohar": { "bigin": "12:20", "iqamah": "13:00" }, "asr": { "bigin": "15:19", "iqamah": "16:30" }, "maghrib": { "bigin": "17:43", "iqamah": "17:46" }, "isha": { "bigin": "19:05", "iqamah": "19:40" } },
        "29": { "fajr": { "bigin": "05:25", "iqamah": "06:10", "sunrise": "06:46" }, "dohar": { "bigin": "12:20", "iqamah": "13:00" }, "asr": { "bigin": "15:18", "iqamah": "16:30" }, "maghrib": { "bigin": "17:42", "iqamah": "17:45" }, "isha": { "bigin": "19:04", "iqamah": "19:40" } },
        "30": { "fajr": { "bigin": "05:26", "iqamah": "06:10", "sunrise": "06:47" }, "dohar": { "bigin": "12:19", "iqamah": "13:00" }, "asr": { "bigin": "15:18", "iqamah": "16:30" }, "maghrib": { "bigin": "17:41", "iqamah": "17:44" }, "isha": { "bigin": "19:03", "iqamah": "19:40" } },
    },
    "4": {
        "1": { "fajr": { "bigin": "05:26", "iqamah": "06:10", "sunrise": "06:48" }, "dohar": { "bigin": "12:19", "iqamah": "13:00" }, "asr": { "bigin": "15:17", "iqamah": "16:15" }, "maghrib": { "bigin": "17:40", "iqamah": "17:43" }, "isha": { "bigin": "19:02", "iqamah": "19:40" } },
        "2": { "fajr": { "bigin": "05:27", "iqamah": "06:10", "sunrise": "06:48" }, "dohar": { "bigin": "12:19", "iqamah": "13:00" }, "asr": { "bigin": "15:16", "iqamah": "16:15" }, "maghrib": { "bigin": "17:39", "iqamah": "17:42" }, "isha": { "bigin": "19:02", "iqamah": "19:40" } },
        "3": { "fajr": { "bigin": "05:27", "iqamah": "06:10", "sunrise": "06:49" }, "dohar": { "bigin": "12:19", "iqamah": "13:00" }, "asr": { "bigin": "15:16", "iqamah": "16:15" }, "maghrib": { "bigin": "17:38", "iqamah": "17:41" }, "isha": { "bigin": "19:01", "iqamah": "19:40" } },
        "4": { "fajr": { "bigin": "05:28", "iqamah": "06:10", "sunrise": "06:50" }, "dohar": { "bigin": "12:19", "iqamah": "13:00" }, "asr": { "bigin": "15:15", "iqamah": "16:15" }, "maghrib": { "bigin": "17:37", "iqamah": "17:40" }, "isha": { "bigin": "19:00", "iqamah": "19:40" } },
        "5": { "fajr": { "bigin": "05:29", "iqamah": "06:10", "sunrise": "06:50" }, "dohar": { "bigin": "12:19", "iqamah": "13:00" }, "asr": { "bigin": "15:14", "iqamah": "16:15" }, "maghrib": { "bigin": "17:36", "iqamah": "17:39" }, "isha": { "bigin": "18:59", "iqamah": "19:40" } },
        "6": { "fajr": { "bigin": "05:29", "iqamah": "06:10", "sunrise": "06:51" }, "dohar": { "bigin": "12:19", "iqamah": "13:00" }, "asr": { "bigin": "15:13", "iqamah": "16:15" }, "maghrib": { "bigin": "17:35", "iqamah": "17:38" }, "isha": { "bigin": "18:59", "iqamah": "19:40" } },
        "7": { "fajr": { "bigin": "05:30", "iqamah": "06:10", "sunrise": "06:52" }, "dohar": { "bigin": "12:19", "iqamah": "13:00" }, "asr": { "bigin": "15:13", "iqamah": "16:15" }, "maghrib": { "bigin": "17:34", "iqamah": "17:37" }, "isha": { "bigin": "18:58", "iqamah": "19:40" } },
        "8": { "fajr": { "bigin": "05:30", "iqamah": "06:10", "sunrise": "06:52" }, "dohar": { "bigin": "12:19", "iqamah": "13:00" }, "asr": { "bigin": "15:12", "iqamah": "16:15" }, "maghrib": { "bigin": "17:34", "iqamah": "17:37" }, "isha": { "bigin": "18:57", "iqamah": "19:40" } },
        "9": { "fajr": { "bigin": "05:31", "iqamah": "06:10", "sunrise": "06:53" }, "dohar": { "bigin": "12:19", "iqamah": "13:00" }, "asr": { "bigin": "15:11", "iqamah": "16:15" }, "maghrib": { "bigin": "17:33", "iqamah": "17:36" }, "isha": { "bigin": "18:56", "iqamah": "19:40" } },
        "10": { "fajr": { "bigin": "05:31", "iqamah": "06:10", "sunrise": "06:54" }, "dohar": { "bigin": "12:19", "iqamah": "13:00" }, "asr": { "bigin": "15:11", "iqamah": "16:15" }, "maghrib": { "bigin": "17:32", "iqamah": "17:35" }, "isha": { "bigin": "18:56", "iqamah": "19:40" } },
        "11": { "fajr": { "bigin": "05:32", "iqamah": "06:10", "sunrise": "06:55" }, "dohar": { "bigin": "12:19", "iqamah": "13:00" }, "asr": { "bigin": "15:10", "iqamah": "16:15" }, "maghrib": { "bigin": "17:31", "iqamah": "17:34" }, "isha": { "bigin": "18:55", "iqamah": "19:40" } },
        "12": { "fajr": { "bigin": "05:32", "iqamah": "06:10", "sunrise": "06:55" }, "dohar": { "bigin": "12:19", "iqamah": "13:00" }, "asr": { "bigin": "15:10", "iqamah": "16:15" }, "maghrib": { "bigin": "17:31", "iqamah": "17:34" }, "isha": { "bigin": "18:55", "iqamah": "19:40" } },
        "13": { "fajr": { "bigin": "05:33", "iqamah": "06:10", "sunrise": "06:56" }, "dohar": { "bigin": "12:19", "iqamah": "13:00" }, "asr": { "bigin": "15:09", "iqamah": "16:15" }, "maghrib": { "bigin": "17:30", "iqamah": "17:33" }, "isha": { "bigin": "18:54", "iqamah": "19:40" } },
        "14": { "fajr": { "bigin": "05:34", "iqamah": "06:10", "sunrise": "06:57" }, "dohar": { "bigin": "12:19", "iqamah": "13:00" }, "asr": { "bigin": "15:09", "iqamah": "16:15" }, "maghrib": { "bigin": "17:29", "iqamah": "17:32" }, "isha": { "bigin": "18:53", "iqamah": "19:40" } },
        "15": { "fajr": { "bigin": "05:34", "iqamah": "06:10", "sunrise": "06:57" }, "dohar": { "bigin": "12:19", "iqamah": "13:00" }, "asr": { "bigin": "15:08", "iqamah": "16:15" }, "maghrib": { "bigin": "17:28", "iqamah": "17:31" }, "isha": { "bigin": "18:53", "iqamah": "19:40" } },
        "16": { "fajr": { "bigin": "05:35", "iqamah": "06:10", "sunrise": "06:58" }, "dohar": { "bigin": "12:19", "iqamah": "13:00" }, "asr": { "bigin": "15:08", "iqamah": "16:15" }, "maghrib": { "bigin": "17:28", "iqamah": "17:31" }, "isha": { "bigin": "18:52", "iqamah": "19:40" } },
        "17": { "fajr": { "bigin": "05:35", "iqamah": "06:10", "sunrise": "06:59" }, "dohar": { "bigin": "12:19", "iqamah": "13:00" }, "asr": { "bigin": "15:07", "iqamah": "16:15" }, "maghrib": { "bigin": "17:27", "iqamah": "17:30" }, "isha": { "bigin": "18:52", "iqamah": "19:40" } },
        "18": { "fajr": { "bigin": "05:36", "iqamah": "06:10", "sunrise": "06:59" }, "dohar": { "bigin": "12:19", "iqamah": "13:00" }, "asr": { "bigin": "15:07", "iqamah": "16:15" }, "maghrib": { "bigin": "17:27", "iqamah": "17:30" }, "isha": { "bigin": "18:51", "iqamah": "19:40" } },
        "19": { "fajr": { "bigin": "05:36", "iqamah": "06:10", "sunrise": "07:00" }, "dohar": { "bigin": "12:19", "iqamah": "13:00" }, "asr": { "bigin": "15:06", "iqamah": "16:15" }, "maghrib": { "bigin": "17:26", "iqamah": "17:29" }, "isha": { "bigin": "18:51", "iqamah": "19:40" } },
        "20": { "fajr": { "bigin": "05:37", "iqamah": "06:10", "sunrise": "07:01" }, "dohar": { "bigin": "12:19", "iqamah": "13:00" }, "asr": { "bigin": "15:06", "iqamah": "16:15" }, "maghrib": { "bigin": "17:26", "iqamah": "17:29" }, "isha": { "bigin": "18:51", "iqamah": "19:40" } },
        "21": { "fajr": { "bigin": "05:37", "iqamah": "06:10", "sunrise": "07:01" }, "dohar": { "bigin": "12:19", "iqamah": "13:00" }, "asr": { "bigin": "15:05", "iqamah": "16:15" }, "maghrib": { "bigin": "17:25", "iqamah": "17:28" }, "isha": { "bigin": "18:50", "iqamah": "19:40" } },
        "22": { "fajr": { "bigin": "05:38", "iqamah": "06:10", "sunrise": "07:02" }, "dohar": { "bigin": "12:19", "iqamah": "13:00" }, "asr": { "bigin": "15:05", "iqamah": "16:15" }, "maghrib": { "bigin": "17:24", "iqamah": "17:27" }, "isha": { "bigin": "18:50", "iqamah": "19:40" } },
        "23": { "fajr": { "bigin": "05:38", "iqamah": "06:10", "sunrise": "07:03" }, "dohar": { "bigin": "12:19", "iqamah": "13:00" }, "asr": { "bigin": "15:04", "iqamah": "16:15" }, "maghrib": { "bigin": "17:24", "iqamah": "17:27" }, "isha": { "bigin": "18:49", "iqamah": "19:40" } },
        "24": { "fajr": { "bigin": "05:39", "iqamah": "06:10", "sunrise": "07:03" }, "dohar": { "bigin": "12:19", "iqamah": "13:00" }, "asr": { "bigin": "15:04", "iqamah": "16:15" }, "maghrib": { "bigin": "17:24", "iqamah": "17:27" }, "isha": { "bigin": "18:49", "iqamah": "19:40" } },
        "25": { "fajr": { "bigin": "05:39", "iqamah": "06:10", "sunrise": "07:04" }, "dohar": { "bigin": "12:19", "iqamah": "13:00" }, "asr": { "bigin": "15:04", "iqamah": "16:15" }, "maghrib": { "bigin": "17:23", "iqamah": "17:26" }, "isha": { "bigin": "18:49", "iqamah": "19:40" } },
        "26": { "fajr": { "bigin": "05:40", "iqamah": "06:10", "sunrise": "07:04" }, "dohar": { "bigin": "12:19", "iqamah": "13:00" }, "asr": { "bigin": "15:03", "iqamah": "16:15" }, "maghrib": { "bigin": "17:23", "iqamah": "17:26" }, "isha": { "bigin": "18:48", "iqamah": "19:40" } },
        "27": { "fajr": { "bigin": "05:40", "iqamah": "06:10", "sunrise": "07:05" }, "dohar": { "bigin": "12:19", "iqamah": "13:00" }, "asr": { "bigin": "15:03", "iqamah": "16:15" }, "maghrib": { "bigin": "17:22", "iqamah": "17:25" }, "isha": { "bigin": "18:48", "iqamah": "19:40" } },
        "28": { "fajr": { "bigin": "05:41", "iqamah": "06:10", "sunrise": "07:06" }, "dohar": { "bigin": "12:19", "iqamah": "13:00" }, "asr": { "bigin": "15:03", "iqamah": "16:15" }, "maghrib": { "bigin": "17:22", "iqamah": "17:25" }, "isha": { "bigin": "18:48", "iqamah": "19:40" } },
        "29": { "fajr": { "bigin": "05:41", "iqamah": "06:10", "sunrise": "07:06" }, "dohar": { "bigin": "12:20", "iqamah": "13:00" }, "asr": { "bigin": "15:03", "iqamah": "16:15" }, "maghrib": { "bigin": "17:22", "iqamah": "17:25" }, "isha": { "bigin": "18:48", "iqamah": "19:40" } },
        "30": { "fajr": { "bigin": "05:42", "iqamah": "06:10", "sunrise": "07:07" }, "dohar": { "bigin": "12:20", "iqamah": "13:00" }, "asr": { "bigin": "15:02", "iqamah": "16:15" }, "maghrib": { "bigin": "17:21", "iqamah": "17:24" }, "isha": { "bigin": "18:47", "iqamah": "19:40" } },
        "31": { "fajr": { "bigin": "05:42", "iqamah": "06:10", "sunrise": "07:07" }, "dohar": { "bigin": "12:20", "iqamah": "13:00" }, "asr": { "bigin": "15:02", "iqamah": "16:15" }, "maghrib": { "bigin": "17:21", "iqamah": "17:24" }, "isha": { "bigin": "18:47", "iqamah": "19:40" } },
    },
    "5": {
        "1": { "fajr": { "begin": "05:43", "iqamah": "06:10", "sunrise": "07:08" }, "dohar": { "begin": "12:20", "iqamah": "13:00" }, "asr": { "begin": "15:02", "iqamah": "16:15" }, "maghrib": { "begin": "17:21", "iqamah": "17:24" }, "isha": { "begin": "18:47", "iqamah": "19:40" } },
        "2": { "fajr": { "begin": "05:43", "iqamah": "06:10", "sunrise": "07:08" }, "dohar": { "begin": "12:20", "iqamah": "13:00" }, "asr": { "begin": "15:02", "iqamah": "16:15" }, "maghrib": { "begin": "17:21", "iqamah": "17:24" }, "isha": { "begin": "18:47", "iqamah": "19:40" } },
        "3": { "fajr": { "begin": "05:44", "iqamah": "06:10", "sunrise": "07:09" }, "dohar": { "begin": "12:20", "iqamah": "13:00" }, "asr": { "begin": "15:02", "iqamah": "16:15" }, "maghrib": { "begin": "17:20", "iqamah": "17:23" }, "isha": { "begin": "18:47", "iqamah": "19:40" } },
        "4": { "fajr": { "begin": "05:44", "iqamah": "06:10", "sunrise": "07:10" }, "dohar": { "begin": "12:20", "iqamah": "13:00" }, "asr": { "begin": "15:01", "iqamah": "16:15" }, "maghrib": { "begin": "17:20", "iqamah": "17:23" }, "isha": { "begin": "18:47", "iqamah": "19:40" } },
        "5": { "fajr": { "begin": "05:45", "iqamah": "06:10", "sunrise": "07:10" }, "dohar": { "begin": "12:21", "iqamah": "13:00" }, "asr": { "begin": "15:01", "iqamah": "16:15" }, "maghrib": { "begin": "17:20", "iqamah": "17:23" }, "isha": { "begin": "18:47", "iqamah": "19:40" } },
        "6": { "fajr": { "begin": "05:45", "iqamah": "06:10", "sunrise": "07:11" }, "dohar": { "begin": "12:21", "iqamah": "13:00" }, "asr": { "begin": "15:01", "iqamah": "16:15" }, "maghrib": { "begin": "17:20", "iqamah": "17:23" }, "isha": { "begin": "18:47", "iqamah": "19:40" } },
        "7": { "fajr": { "begin": "05:45", "iqamah": "06:10", "sunrise": "07:11" }, "dohar": { "begin": "12:21", "iqamah": "13:00" }, "asr": { "begin": "15:01", "iqamah": "16:15" }, "maghrib": { "begin": "17:20", "iqamah": "17:23" }, "isha": { "begin": "18:47", "iqamah": "19:40" } },
        "8": { "fajr": { "begin": "05:46", "iqamah": "06:10", "sunrise": "07:12" }, "dohar": { "begin": "12:21", "iqamah": "13:00" }, "asr": { "begin": "15:01", "iqamah": "16:15" }, "maghrib": { "begin": "17:20", "iqamah": "17:23" }, "isha": { "begin": "18:47", "iqamah": "19:40" } },
        "9": { "fajr": { "begin": "05:46", "iqamah": "06:10", "sunrise": "07:12" }, "dohar": { "begin": "12:21", "iqamah": "13:00" }, "asr": { "begin": "15:01", "iqamah": "16:15" }, "maghrib": { "begin": "17:20", "iqamah": "17:23" }, "isha": { "begin": "18:47", "iqamah": "19:40" } },
        "10": { "fajr": { "begin": "05:47", "iqamah": "06:10", "sunrise": "07:12" }, "dohar": { "begin": "12:22", "iqamah": "13:00" }, "asr": { "begin": "15:01", "iqamah": "16:15" }, "maghrib": { "begin": "17:20", "iqamah": "17:23" }, "isha": { "begin": "18:47", "iqamah": "19:40" } },
        "11": { "fajr": { "begin": "05:47", "iqamah": "06:10", "sunrise": "07:13" }, "dohar": { "begin": "12:22", "iqamah": "13:00" }, "asr": { "begin": "15:01", "iqamah": "16:15" }, "maghrib": { "begin": "17:20", "iqamah": "17:23" }, "isha": { "begin": "18:47", "iqamah": "19:40" } },
        "12": { "fajr": { "begin": "05:47", "iqamah": "06:10", "sunrise": "07:13" }, "dohar": { "begin": "12:22", "iqamah": "13:00" }, "asr": { "begin": "15:01", "iqamah": "16:15" }, "maghrib": { "begin": "17:20", "iqamah": "17:23" }, "isha": { "begin": "18:47", "iqamah": "19:40" } },
        "13": { "fajr": { "begin": "05:48", "iqamah": "06:10", "sunrise": "07:14" }, "dohar": { "begin": "12:22", "iqamah": "13:00" }, "asr": { "begin": "15:01", "iqamah": "16:15" }, "maghrib": { "begin": "17:20", "iqamah": "17:23" }, "isha": { "begin": "18:47", "iqamah": "19:40" } },
        "14": { "fajr": { "begin": "05:48", "iqamah": "06:10", "sunrise": "07:14" }, "dohar": { "begin": "12:22", "iqamah": "13:00" }, "asr": { "begin": "15:01", "iqamah": "16:15" }, "maghrib": { "begin": "17:20", "iqamah": "17:23" }, "isha": { "begin": "18:47", "iqamah": "19:40" } },
        "15": { "fajr": { "begin": "05:48", "iqamah": "06:10", "sunrise": "07:14" }, "dohar": { "begin": "12:23", "iqamah": "13:00" }, "asr": { "begin": "15:01", "iqamah": "16:15" }, "maghrib": { "begin": "17:20", "iqamah": "17:23" }, "isha": { "begin": "18:47", "iqamah": "19:40" } },
        "16": { "fajr": { "begin": "05:48", "iqamah": "06:10", "sunrise": "07:15" }, "dohar": { "begin": "12:23", "iqamah": "13:00" }, "asr": { "begin": "15:02", "iqamah": "16:15" }, "maghrib": { "begin": "17:20", "iqamah": "17:23" }, "isha": { "begin": "18:47", "iqamah": "19:40" } },
        "17": { "fajr": { "begin": "05:49", "iqamah": "06:10", "sunrise": "07:15" }, "dohar": { "begin": "12:23", "iqamah": "13:00" }, "asr": { "begin": "15:02", "iqamah": "16:15" }, "maghrib": { "begin": "17:20", "iqamah": "17:23" }, "isha": { "begin": "18:47", "iqamah": "19:40" } },
        "18": { "fajr": { "begin": "05:49", "iqamah": "06:10", "sunrise": "07:15" }, "dohar": { "begin": "12:23", "iqamah": "13:00" }, "asr": { "begin": "15:02", "iqamah": "16:15" }, "maghrib": { "begin": "17:20", "iqamah": "17:23" }, "isha": { "begin": "18:47", "iqamah": "19:40" } },
        "19": { "fajr": { "begin": "05:49", "iqamah": "06:10", "sunrise": "07:16" }, "dohar": { "begin": "12:23", "iqamah": "13:00" }, "asr": { "begin": "15:02", "iqamah": "16:15" }, "maghrib": { "begin": "17:20", "iqamah": "17:23" }, "isha": { "begin": "18:48", "iqamah": "19:40" } },
        "20": { "fajr": { "begin": "05:50", "iqamah": "06:10", "sunrise": "07:16" }, "dohar": { "begin": "12:24", "iqamah": "13:00" }, "asr": { "begin": "15:02", "iqamah": "16:15" }, "maghrib": { "begin": "17:20", "iqamah": "17:23" }, "isha": { "begin": "18:48", "iqamah": "19:40" } },
        "21": { "fajr": { "begin": "05:50", "iqamah": "06:10", "sunrise": "07:16" }, "dohar": { "begin": "12:24", "iqamah": "13:00" }, "asr": { "begin": "15:02", "iqamah": "16:15" }, "maghrib": { "begin": "17:21", "iqamah": "17:24" }, "isha": { "begin": "18:48", "iqamah": "19:40" } },
        "22": { "fajr": { "begin": "05:50", "iqamah": "06:10", "sunrise": "07:16" }, "dohar": { "begin": "12:24", "iqamah": "13:00" }, "asr": { "begin": "15:03", "iqamah": "16:15" }, "maghrib": { "begin": "17:21", "iqamah": "17:24" }, "isha": { "begin": "18:48", "iqamah": "19:40" } },
        "23": { "fajr": { "begin": "05:50", "iqamah": "06:10", "sunrise": "07:17" }, "dohar": { "begin": "12:24", "iqamah": "13:00" }, "asr": { "begin": "15:03", "iqamah": "16:15" }, "maghrib": { "begin": "17:21", "iqamah": "17:24" }, "isha": { "begin": "18:48", "iqamah": "19:40" } },
        "24": { "fajr": { "begin": "05:50", "iqamah": "06:10", "sunrise": "07:17" }, "dohar": { "begin": "12:25", "iqamah": "13:00" }, "asr": { "begin": "15:03", "iqamah": "16:15" }, "maghrib": { "begin": "17:21", "iqamah": "17:24" }, "isha": { "begin": "18:49", "iqamah": "19:40" } },
        "25": { "fajr": { "begin": "05:50", "iqamah": "06:10", "sunrise": "07:17" }, "dohar": { "begin": "12:25", "iqamah": "13:00" }, "asr": { "begin": "15:04", "iqamah": "16:15" }, "maghrib": { "begin": "17:22", "iqamah": "17:25" }, "isha": { "begin": "18:49", "iqamah": "19:40" } },
        "26": { "fajr": { "begin": "05:51", "iqamah": "06:10", "sunrise": "07:17" }, "dohar": { "begin": "12:25", "iqamah": "13:00" }, "asr": { "begin": "15:04", "iqamah": "16:15" }, "maghrib": { "begin": "17:22", "iqamah": "17:25" }, "isha": { "begin": "18:49", "iqamah": "19:40" } },
        "27": { "fajr": { "begin": "05:51", "iqamah": "06:10", "sunrise": "07:17" }, "dohar": { "begin": "12:25", "iqamah": "13:00" }, "asr": { "begin": "15:04", "iqamah": "16:15" }, "maghrib": { "begin": "17:22", "iqamah": "17:25" }, "isha": { "begin": "18:50", "iqamah": "19:40" } },
        "28": { "fajr": { "begin": "05:51", "iqamah": "06:10", "sunrise": "07:17" }, "dohar": { "begin": "12:25", "iqamah": "13:00" }, "asr": { "begin": "15:04", "iqamah": "16:15" }, "maghrib": { "begin": "17:23", "iqamah": "17:26" }, "isha": { "begin": "18:50", "iqamah": "19:40" } },
        "29": { "fajr": { "begin": "05:51", "iqamah": "06:10", "sunrise": "07:17" }, "dohar": { "begin": "12:26", "iqamah": "13:00" }, "asr": { "begin": "15:05", "iqamah": "16:15" }, "maghrib": { "begin": "17:23", "iqamah": "17:26" }, "isha": { "begin": "18:50", "iqamah": "19:40" } },
        "30": { "fajr": { "begin": "05:51", "iqamah": "06:10", "sunrise": "07:17" }, "dohar": { "begin": "12:26", "iqamah": "13:00" }, "asr": { "begin": "15:05", "iqamah": "16:15" }, "maghrib": { "begin": "17:23", "iqamah": "17:26" }, "isha": { "begin": "18:51", "iqamah": "19:40" } },
    },
    "6": {
        "1": { "fajr": { "begin": "05:51", "iqamah": "06:10", "sunrise": "07:17" }, "dohar": { "begin": "12:26", "iqamah": "13:00" }, "asr": { "begin": "15:06", "iqamah": "16:15" }, "maghrib": { "begin": "17:24", "iqamah": "17:27" }, "isha": { "begin": "18:51", "iqamah": "19:40" } },
        "2": { "fajr": { "begin": "05:51", "iqamah": "06:10", "sunrise": "07:17" }, "dohar": { "begin": "12:26", "iqamah": "13:00" }, "asr": { "begin": "15:06", "iqamah": "16:15" }, "maghrib": { "begin": "17:24", "iqamah": "17:27" }, "isha": { "begin": "18:51", "iqamah": "19:40" } },
        "3": { "fajr": { "begin": "05:51", "iqamah": "06:10", "sunrise": "07:17" }, "dohar": { "begin": "12:26", "iqamah": "13:00" }, "asr": { "begin": "15:06", "iqamah": "16:15" }, "maghrib": { "begin": "17:25", "iqamah": "17:28" }, "isha": { "begin": "18:52", "iqamah": "19:40" } },
        "4": { "fajr": { "begin": "05:51", "iqamah": "06:10", "sunrise": "07:17" }, "dohar": { "begin": "12:27", "iqamah": "13:00" }, "asr": { "begin": "15:07", "iqamah": "16:15" }, "maghrib": { "begin": "17:25", "iqamah": "17:28" }, "isha": { "begin": "18:52", "iqamah": "19:40" } },
        "5": { "fajr": { "begin": "05:51", "iqamah": "06:10", "sunrise": "07:17" }, "dohar": { "begin": "12:27", "iqamah": "13:00" }, "asr": { "begin": "15:07", "iqamah": "16:15" }, "maghrib": { "begin": "17:25", "iqamah": "17:28" }, "isha": { "begin": "18:52", "iqamah": "19:40" } },
        "6": { "fajr": { "begin": "05:51", "iqamah": "06:10", "sunrise": "07:17" }, "dohar": { "begin": "12:27", "iqamah": "13:00" }, "asr": { "begin": "15:08", "iqamah": "16:15" }, "maghrib": { "begin": "17:26", "iqamah": "17:29" }, "isha": { "begin": "18:53", "iqamah": "19:40" } },
        "7": { "fajr": { "begin": "05:51", "iqamah": "06:10", "sunrise": "07:17" }, "dohar": { "begin": "12:27", "iqamah": "13:00" }, "asr": { "begin": "15:08", "iqamah": "16:15" }, "maghrib": { "begin": "17:26", "iqamah": "17:29" }, "isha": { "begin": "18:53", "iqamah": "19:40" } },
        "8": { "fajr": { "begin": "05:51", "iqamah": "06:10", "sunrise": "07:17" }, "dohar": { "begin": "12:27", "iqamah": "13:00" }, "asr": { "begin": "15:08", "iqamah": "16:15" }, "maghrib": { "begin": "17:27", "iqamah": "17:30" }, "isha": { "begin": "18:54", "iqamah": "19:40" } },
        "9": { "fajr": { "begin": "05:51", "iqamah": "06:10", "sunrise": "07:16" }, "dohar": { "begin": "12:27", "iqamah": "13:00" }, "asr": { "begin": "15:09", "iqamah": "16:15" }, "maghrib": { "begin": "17:27", "iqamah": "17:30" }, "isha": { "begin": "18:54", "iqamah": "19:40" } },
        "10": { "fajr": { "begin": "05:51", "iqamah": "06:10", "sunrise": "07:16" }, "dohar": { "begin": "12:28", "iqamah": "13:00" }, "asr": { "begin": "15:09", "iqamah": "16:15" }, "maghrib": { "begin": "17:28", "iqamah": "17:31" }, "isha": { "begin": "18:54", "iqamah": "19:40" } },
        "11": { "fajr": { "begin": "05:50", "iqamah": "06:10", "sunrise": "07:16" }, "dohar": { "begin": "12:28", "iqamah": "13:00" }, "asr": { "begin": "15:10", "iqamah": "16:15" }, "maghrib": { "begin": "17:28", "iqamah": "17:31" }, "isha": { "begin": "18:55", "iqamah": "19:40" } },
        "12": { "fajr": { "begin": "05:50", "iqamah": "06:10", "sunrise": "07:16" }, "dohar": { "begin": "12:28", "iqamah": "13:00" }, "asr": { "begin": "15:10", "iqamah": "16:15" }, "maghrib": { "begin": "17:29", "iqamah": "17:32" }, "isha": { "begin": "18:55", "iqamah": "19:40" } },
        "13": { "fajr": { "begin": "05:50", "iqamah": "06:10", "sunrise": "07:15" }, "dohar": { "begin": "12:28", "iqamah": "13:00" }, "asr": { "begin": "15:11", "iqamah": "16:15" }, "maghrib": { "begin": "17:30", "iqamah": "17:33" }, "isha": { "begin": "18:56", "iqamah": "19:40" } },
        "14": { "fajr": { "begin": "05:50", "iqamah": "06:10", "sunrise": "07:15" }, "dohar": { "begin": "12:28", "iqamah": "13:00" }, "asr": { "begin": "15:11", "iqamah": "16:15" }, "maghrib": { "begin": "17:30", "iqamah": "17:33" }, "isha": { "begin": "18:56", "iqamah": "19:40" } },
        "15": { "fajr": { "begin": "05:49", "iqamah": "06:10", "sunrise": "07:15" }, "dohar": { "begin": "12:28", "iqamah": "13:00" }, "asr": { "begin": "15:12", "iqamah": "16:15" }, "maghrib": { "begin": "17:31", "iqamah": "17:34" }, "isha": { "begin": "18:57", "iqamah": "19:40" } },
        "16": { "fajr": { "begin": "05:49", "iqamah": "06:10", "sunrise": "07:14" }, "dohar": { "begin": "12:28", "iqamah": "13:00" }, "asr": { "begin": "15:12", "iqamah": "16:15" }, "maghrib": { "begin": "17:31", "iqamah": "17:34" }, "isha": { "begin": "18:57", "iqamah": "19:40" } },
        "17": { "fajr": { "begin": "05:49", "iqamah": "06:10", "sunrise": "07:14" }, "dohar": { "begin": "12:28", "iqamah": "13:00" }, "asr": { "begin": "15:13", "iqamah": "16:15" }, "maghrib": { "begin": "17:32", "iqamah": "17:35" }, "isha": { "begin": "18:58", "iqamah": "19:40" } },
        "18": { "fajr": { "begin": "05:49", "iqamah": "06:10", "sunrise": "07:14" }, "dohar": { "begin": "12:28", "iqamah": "13:00" }, "asr": { "begin": "15:13", "iqamah": "16:15" }, "maghrib": { "begin": "17:32", "iqamah": "17:35" }, "isha": { "begin": "18:58", "iqamah": "19:40" } },
        "19": { "fajr": { "begin": "05:48", "iqamah": "06:10", "sunrise": "07:13" }, "dohar": { "begin": "12:28", "iqamah": "13:00" }, "asr": { "begin": "15:14", "iqamah": "16:15" }, "maghrib": { "begin": "17:33", "iqamah": "17:36" }, "isha": { "begin": "18:59", "iqamah": "19:40" } },
        "20": { "fajr": { "begin": "05:48", "iqamah": "06:10", "sunrise": "07:13" }, "dohar": { "begin": "12:29", "iqamah": "13:00" }, "asr": { "begin": "15:14", "iqamah": "16:15" }, "maghrib": { "begin": "17:34", "iqamah": "17:37" }, "isha": { "begin": "18:59", "iqamah": "19:40" } },
        "21": { "fajr": { "begin": "05:48", "iqamah": "06:10", "sunrise": "07:12" }, "dohar": { "begin": "12:29", "iqamah": "13:00" }, "asr": { "begin": "15:15", "iqamah": "16:15" }, "maghrib": { "begin": "17:34", "iqamah": "17:37" }, "isha": { "begin": "19:00", "iqamah": "19:40" } },
        "22": { "fajr": { "begin": "05:47", "iqamah": "06:10", "sunrise": "07:12" }, "dohar": { "begin": "12:29", "iqamah": "13:00" }, "asr": { "begin": "15:15", "iqamah": "16:15" }, "maghrib": { "begin": "17:35", "iqamah": "17:38" }, "isha": { "begin": "19:00", "iqamah": "19:40" } },
        "23": { "fajr": { "begin": "05:47", "iqamah": "06:10", "sunrise": "07:11" }, "dohar": { "begin": "12:29", "iqamah": "13:00" }, "asr": { "begin": "15:16", "iqamah": "16:15" }, "maghrib": { "begin": "17:35", "iqamah": "17:38" }, "isha": { "begin": "19:01", "iqamah": "19:40" } },
        "24": { "fajr": { "begin": "05:46", "iqamah": "06:10", "sunrise": "07:11" }, "dohar": { "begin": "12:29", "iqamah": "13:00" }, "asr": { "begin": "15:16", "iqamah": "16:15" }, "maghrib": { "begin": "17:36", "iqamah": "17:39" }, "isha": { "begin": "19:01", "iqamah": "19:40" } },
        "25": { "fajr": { "begin": "05:46", "iqamah": "06:10", "sunrise": "07:10" }, "dohar": { "begin": "12:29", "iqamah": "13:00" }, "asr": { "begin": "15:17", "iqamah": "16:15" }, "maghrib": { "begin": "17:37", "iqamah": "17:40" }, "isha": { "begin": "19:02", "iqamah": "19:40" } },
        "26": { "fajr": { "begin": "05:45", "iqamah": "06:10", "sunrise": "07:09" }, "dohar": { "begin": "12:29", "iqamah": "13:00" }, "asr": { "begin": "15:17", "iqamah": "16:15" }, "maghrib": { "begin": "17:37", "iqamah": "17:40" }, "isha": { "begin": "19:02", "iqamah": "19:40" } },
        "27": { "fajr": { "begin": "05:45", "iqamah": "06:10", "sunrise": "07:09" }, "dohar": { "begin": "12:29", "iqamah": "13:00" }, "asr": { "begin": "15:18", "iqamah": "16:15" }, "maghrib": { "begin": "17:38", "iqamah": "17:41" }, "isha": { "begin": "19:03", "iqamah": "19:40" } },
        "28": { "fajr": { "begin": "05:44", "iqamah": "06:10", "sunrise": "07:08" }, "dohar": { "begin": "12:29", "iqamah": "13:00" }, "asr": { "begin": "15:18", "iqamah": "16:15" }, "maghrib": { "begin": "17:39", "iqamah": "17:42" }, "isha": { "begin": "19:03", "iqamah": "19:40" } },
        "29": { "fajr": { "begin": "05:44", "iqamah": "06:10", "sunrise": "07:08" }, "dohar": { "begin": "12:29", "iqamah": "13:00" }, "asr": { "begin": "15:19", "iqamah": "16:15" }, "maghrib": { "begin": "17:39", "iqamah": "17:42" }, "isha": { "begin": "19:04", "iqamah": "19:40" } },
        "30": { "fajr": { "begin": "05:43", "iqamah": "06:10", "sunrise": "07:07" }, "dohar": { "begin": "12:29", "iqamah": "13:00" }, "asr": { "begin": "15:19", "iqamah": "16:15" }, "maghrib": { "begin": "17:40", "iqamah": "17:43" }, "isha": { "begin": "19:04", "iqamah": "19:40" } },
        "31": { "fajr": { "begin": "05:42", "iqamah": "06:10", "sunrise": "07:06" }, "dohar": { "begin": "12:29", "iqamah": "13:00" }, "asr": { "begin": "15:20", "iqamah": "16:15" }, "maghrib": { "begin": "17:40", "iqamah": "17:43" }, "isha": { "begin": "19:05", "iqamah": "19:40" } },
    },
    "7": {
        "1": { "fajr": { "begin": "05:42", "iqamah": "06:00", "sunrise": "07:05" }, "dohar": { "begin": "12:29", "iqamah": "13:00" }, "asr": { "begin": "15:20", "iqamah": "16:30" }, "maghrib": { "begin": "17:41", "iqamah": "17:44" }, "isha": { "begin": "19:05", "iqamah": "19:40" } },
        "2": { "fajr": { "begin": "05:41", "iqamah": "06:00", "sunrise": "07:05" }, "dohar": { "begin": "12:28", "iqamah": "13:00" }, "asr": { "begin": "15:21", "iqamah": "16:30" }, "maghrib": { "begin": "17:42", "iqamah": "17:45" }, "isha": { "begin": "19:06", "iqamah": "19:40" } },
        "3": { "fajr": { "begin": "05:40", "iqamah": "06:00", "sunrise": "07:04" }, "dohar": { "begin": "12:28", "iqamah": "13:00" }, "asr": { "begin": "15:21", "iqamah": "16:30" }, "maghrib": { "begin": "17:42", "iqamah": "17:45" }, "isha": { "begin": "19:06", "iqamah": "19:40" } },
        "4": { "fajr": { "begin": "05:40", "iqamah": "06:00", "sunrise": "07:03" }, "dohar": { "begin": "12:28", "iqamah": "13:00" }, "asr": { "begin": "15:22", "iqamah": "16:30" }, "maghrib": { "begin": "17:43", "iqamah": "17:46" }, "isha": { "begin": "19:07", "iqamah": "19:40" } },
        "5": { "fajr": { "begin": "05:39", "iqamah": "06:00", "sunrise": "07:02" }, "dohar": { "begin": "12:28", "iqamah": "13:00" }, "asr": { "begin": "15:22", "iqamah": "16:30" }, "maghrib": { "begin": "17:44", "iqamah": "17:47" }, "isha": { "begin": "19:07", "iqamah": "19:40" } },
        "6": { "fajr": { "begin": "05:38", "iqamah": "06:00", "sunrise": "07:01" }, "dohar": { "begin": "12:28", "iqamah": "13:00" }, "asr": { "begin": "15:23", "iqamah": "16:30" }, "maghrib": { "begin": "17:44", "iqamah": "17:47" }, "isha": { "begin": "19:08", "iqamah": "19:40" } },
        "7": { "fajr": { "begin": "05:38", "iqamah": "06:00", "sunrise": "07:00" }, "dohar": { "begin": "12:28", "iqamah": "13:00" }, "asr": { "begin": "15:23", "iqamah": "16:30" }, "maghrib": { "begin": "17:45", "iqamah": "17:48" }, "isha": { "begin": "19:08", "iqamah": "19:40" } },
        "8": { "fajr": { "begin": "05:37", "iqamah": "06:00", "sunrise": "07:00" }, "dohar": { "begin": "12:28", "iqamah": "13:00" }, "asr": { "begin": "15:24", "iqamah": "16:30" }, "maghrib": { "begin": "17:46", "iqamah": "17:49" }, "isha": { "begin": "19:09", "iqamah": "19:40" } },
        "9": { "fajr": { "begin": "05:36", "iqamah": "06:00", "sunrise": "06:59" }, "dohar": { "begin": "12:28", "iqamah": "13:00" }, "asr": { "begin": "15:24", "iqamah": "16:30" }, "maghrib": { "begin": "17:46", "iqamah": "17:49" }, "isha": { "begin": "19:10", "iqamah": "19:40" } },
        "10": { "fajr": { "begin": "05:35", "iqamah": "06:00", "sunrise": "06:58" }, "dohar": { "begin": "12:28", "iqamah": "13:00" }, "asr": { "begin": "15:25", "iqamah": "16:30" }, "maghrib": { "begin": "17:47", "iqamah": "17:50" }, "isha": { "begin": "19:10", "iqamah": "19:40" } },
        "11": { "fajr": { "begin": "05:34", "iqamah": "06:00", "sunrise": "06:57" }, "dohar": { "begin": "12:27", "iqamah": "13:00" }, "asr": { "begin": "15:25", "iqamah": "16:30" }, "maghrib": { "begin": "17:47", "iqamah": "17:50" }, "isha": { "begin": "19:11", "iqamah": "19:40" } },
        "12": { "fajr": { "begin": "05:33", "iqamah": "06:00", "sunrise": "06:56" }, "dohar": { "begin": "12:27", "iqamah": "13:00" }, "asr": { "begin": "15:26", "iqamah": "16:30" }, "maghrib": { "begin": "17:48", "iqamah": "17:51" }, "isha": { "begin": "19:11", "iqamah": "19:40" } },
        "13": { "fajr": { "begin": "05:33", "iqamah": "06:00", "sunrise": "06:55" }, "dohar": { "begin": "12:27", "iqamah": "13:00" }, "asr": { "begin": "15:26", "iqamah": "16:30" }, "maghrib": { "begin": "17:49", "iqamah": "17:52" }, "isha": { "begin": "19:12", "iqamah": "19:40" } },
        "14": { "fajr": { "begin": "05:32", "iqamah": "06:00", "sunrise": "06:54" }, "dohar": { "begin": "12:27", "iqamah": "13:00" }, "asr": { "begin": "15:26", "iqamah": "16:30" }, "maghrib": { "begin": "17:49", "iqamah": "17:52" }, "isha": { "begin": "19:12", "iqamah": "19:40" } },
        "15": { "fajr": { "begin": "05:31", "iqamah": "06:00", "sunrise": "06:53" }, "dohar": { "begin": "12:27", "iqamah": "13:00" }, "asr": { "begin": "15:27", "iqamah": "16:30" }, "maghrib": { "begin": "17:50", "iqamah": "17:53" }, "isha": { "begin": "19:13", "iqamah": "19:40" } },
        "16": { "fajr": { "begin": "05:30", "iqamah": "06:00", "sunrise": "06:52" }, "dohar": { "begin": "12:27", "iqamah": "13:00" }, "asr": { "begin": "15:27", "iqamah": "16:30" }, "maghrib": { "begin": "17:51", "iqamah": "17:54" }, "isha": { "begin": "19:13", "iqamah": "19:40" } },
        "17": { "fajr": { "begin": "05:29", "iqamah": "06:00", "sunrise": "06:51" }, "dohar": { "begin": "12:26", "iqamah": "13:00" }, "asr": { "begin": "15:28", "iqamah": "16:30" }, "maghrib": { "begin": "17:51", "iqamah": "17:54" }, "isha": { "begin": "19:14", "iqamah": "19:40" } },
        "18": { "fajr": { "begin": "05:28", "iqamah": "06:00", "sunrise": "06:50" }, "dohar": { "begin": "12:26", "iqamah": "13:00" }, "asr": { "begin": "15:28", "iqamah": "16:30" }, "maghrib": { "begin": "17:52", "iqamah": "17:55" }, "isha": { "begin": "19:14", "iqamah": "19:40" } },
        "19": { "fajr": { "begin": "05:27", "iqamah": "06:00", "sunrise": "06:49" }, "dohar": { "begin": "12:26", "iqamah": "13:00" }, "asr": { "begin": "15:29", "iqamah": "16:30" }, "maghrib": { "begin": "17:53", "iqamah": "17:56" }, "isha": { "begin": "19:15", "iqamah": "19:40" } },
        "20": { "fajr": { "begin": "05:26", "iqamah": "06:00", "sunrise": "06:48" }, "dohar": { "begin": "12:26", "iqamah": "13:00" }, "asr": { "begin": "15:29", "iqamah": "16:30" }, "maghrib": { "begin": "17:53", "iqamah": "17:56" }, "isha": { "begin": "19:15", "iqamah": "19:40" } },
        "21": { "fajr": { "begin": "05:25", "iqamah": "06:00", "sunrise": "06:46" }, "dohar": { "begin": "12:25", "iqamah": "13:00" }, "asr": { "begin": "15:29", "iqamah": "16:30" }, "maghrib": { "begin": "17:54", "iqamah": "17:57" }, "isha": { "begin": "19:16", "iqamah": "19:40" } },
        "22": { "fajr": { "begin": "05:24", "iqamah": "06:00", "sunrise": "06:45" }, "dohar": { "begin": "12:25", "iqamah": "13:00" }, "asr": { "begin": "15:30", "iqamah": "16:30" }, "maghrib": { "begin": "17:54", "iqamah": "17:57" }, "isha": { "begin": "19:16", "iqamah": "19:40" } },
        "23": { "fajr": { "begin": "05:23", "iqamah": "06:00", "sunrise": "06:44" }, "dohar": { "begin": "12:25", "iqamah": "13:00" }, "asr": { "begin": "15:30", "iqamah": "16:30" }, "maghrib": { "begin": "17:55", "iqamah": "17:58" }, "isha": { "begin": "19:17", "iqamah": "19:40" } },
        "24": { "fajr": { "begin": "05:22", "iqamah": "06:00", "sunrise": "06:43" }, "dohar": { "begin": "12:25", "iqamah": "13:00" }, "asr": { "begin": "15:30", "iqamah": "16:30" }, "maghrib": { "begin": "17:56", "iqamah": "17:59" }, "isha": { "begin": "19:18", "iqamah": "19:40" } },
        "25": { "fajr": { "begin": "05:21", "iqamah": "06:00", "sunrise": "06:42" }, "dohar": { "begin": "12:24", "iqamah": "13:00" }, "asr": { "begin": "15:31", "iqamah": "16:30" }, "maghrib": { "begin": "17:56", "iqamah": "17:59" }, "isha": { "begin": "19:18", "iqamah": "19:40" } },
        "26": { "fajr": { "begin": "05:20", "iqamah": "06:00", "sunrise": "06:41" }, "dohar": { "begin": "12:24", "iqamah": "13:00" }, "asr": { "begin": "15:31", "iqamah": "16:30" }, "maghrib": { "begin": "17:57", "iqamah": "18:00" }, "isha": { "begin": "19:19", "iqamah": "19:40" } },
        "27": { "fajr": { "begin": "05:18", "iqamah": "06:00", "sunrise": "06:40" }, "dohar": { "begin": "12:24", "iqamah": "13:00" }, "asr": { "begin": "15:31", "iqamah": "16:30" }, "maghrib": { "begin": "17:57", "iqamah": "18:00" }, "isha": { "begin": "19:19", "iqamah": "19:40" } },
        "28": { "fajr": { "begin": "05:17", "iqamah": "06:00", "sunrise": "06:38" }, "dohar": { "begin": "12:24", "iqamah": "13:00" }, "asr": { "begin": "15:32", "iqamah": "16:30" }, "maghrib": { "begin": "17:58", "iqamah": "18:01" }, "isha": { "begin": "19:20", "iqamah": "19:40" } },
        "29": { "fajr": { "begin": "05:16", "iqamah": "06:00", "sunrise": "06:37" }, "dohar": { "begin": "12:23", "iqamah": "13:00" }, "asr": { "begin": "15:32", "iqamah": "16:30" }, "maghrib": { "begin": "17:59", "iqamah": "18:02" }, "isha": { "begin": "19:20", "iqamah": "19:40" } },
        "30": { "fajr": { "begin": "05:15", "iqamah": "06:00", "sunrise": "06:36" }, "dohar": { "begin": "12:23", "iqamah": "13:00" }, "asr": { "begin": "15:32", "iqamah": "16:30" }, "maghrib": { "begin": "17:59", "iqamah": "18:02" }, "isha": { "begin": "19:21", "iqamah": "19:40" } },
        "31": { "fajr": { "begin": "05:14", "iqamah": "06:00", "sunrise": "06:35" }, "dohar": { "begin": "12:23", "iqamah": "13:00" }, "asr": { "begin": "15:33", "iqamah": "16:30" }, "maghrib": { "begin": "18:00", "iqamah": "18:03" }, "isha": { "begin": "19:21", "iqamah": "19:40" } },
    },
    "8": {
        "1": { "fajr": { "begin": "05:13", "iqamah": "05:50", "sunrise": "06:34" }, "dohar": { "begin": "12:22", "iqamah": "13:00" }, "asr": { "begin": "15:33", "iqamah": "16:35" }, "maghrib": { "begin": "18:01", "iqamah": "18:04" }, "isha": { "begin": "19:22", "iqamah": "19:40" } },
        "2": { "fajr": { "begin": "05:11", "iqamah": "05:50", "sunrise": "06:32" }, "dohar": { "begin": "12:22", "iqamah": "13:00" }, "asr": { "begin": "15:33", "iqamah": "16:35" }, "maghrib": { "begin": "18:01", "iqamah": "18:04" }, "isha": { "begin": "19:23", "iqamah": "19:40" } },
        "3": { "fajr": { "begin": "05:10", "iqamah": "05:50", "sunrise": "06:31" }, "dohar": { "begin": "12:22", "iqamah": "13:00" }, "asr": { "begin": "15:34", "iqamah": "16:35" }, "maghrib": { "begin": "18:02", "iqamah": "18:05" }, "isha": { "begin": "19:23", "iqamah": "19:40" } },
        "4": { "fajr": { "begin": "05:09", "iqamah": "05:50", "sunrise": "06:30" }, "dohar": { "begin": "12:21", "iqamah": "13:00" }, "asr": { "begin": "15:34", "iqamah": "16:35" }, "maghrib": { "begin": "18:02", "iqamah": "18:05" }, "isha": { "begin": "19:24", "iqamah": "19:40" } },
        "5": { "fajr": { "begin": "05:08", "iqamah": "05:50", "sunrise": "06:29" }, "dohar": { "begin": "12:21", "iqamah": "13:00" }, "asr": { "begin": "15:34", "iqamah": "16:35" }, "maghrib": { "begin": "18:03", "iqamah": "18:06" }, "isha": { "begin": "19:24", "iqamah": "19:40" } },
        "6": { "fajr": { "begin": "05:06", "iqamah": "05:50", "sunrise": "06:27" }, "dohar": { "begin": "12:21", "iqamah": "13:00" }, "asr": { "begin": "15:34", "iqamah": "16:35" }, "maghrib": { "begin": "18:04", "iqamah": "18:07" }, "isha": { "begin": "19:25", "iqamah": "19:40" } },
        "7": { "fajr": { "begin": "05:05", "iqamah": "05:50", "sunrise": "06:26" }, "dohar": { "begin": "12:20", "iqamah": "13:00" }, "asr": { "begin": "15:34", "iqamah": "16:35" }, "maghrib": { "begin": "18:04", "iqamah": "18:07" }, "isha": { "begin": "19:26", "iqamah": "19:40" } },
        "8": { "fajr": { "begin": "05:04", "iqamah": "05:50", "sunrise": "06:25" }, "dohar": { "begin": "12:20", "iqamah": "13:00" }, "asr": { "begin": "15:35", "iqamah": "16:35" }, "maghrib": { "begin": "18:05", "iqamah": "18:08" }, "isha": { "begin": "19:26", "iqamah": "19:40" } },
        "9": { "fajr": { "begin": "05:03", "iqamah": "05:50", "sunrise": "06:23" }, "dohar": { "begin": "12:20", "iqamah": "13:00" }, "asr": { "begin": "15:35", "iqamah": "16:35" }, "maghrib": { "begin": "18:05", "iqamah": "18:08" }, "isha": { "begin": "19:27", "iqamah": "19:40" } },
        "10": { "fajr": { "begin": "05:01", "iqamah": "05:50", "sunrise": "06:22" }, "dohar": { "begin": "12:19", "iqamah": "13:00" }, "asr": { "begin": "15:35", "iqamah": "16:35" }, "maghrib": { "begin": "18:06", "iqamah": "18:09" }, "isha": { "begin": "19:27", "iqamah": "19:40" } },
        "11": { "fajr": { "begin": "05:00", "iqamah": "05:40", "sunrise": "06:21" }, "dohar": { "begin": "12:19", "iqamah": "13:00" }, "asr": { "begin": "15:35", "iqamah": "16:35" }, "maghrib": { "begin": "18:07", "iqamah": "18:10" }, "isha": { "begin": "19:28", "iqamah": "19:50" } },
        "12": { "fajr": { "begin": "04:59", "iqamah": "05:40", "sunrise": "06:20" }, "dohar": { "begin": "12:19", "iqamah": "13:00" }, "asr": { "begin": "15:36", "iqamah": "16:35" }, "maghrib": { "begin": "18:07", "iqamah": "18:10" }, "isha": { "begin": "19:29", "iqamah": "19:50" } },
        "13": { "fajr": { "begin": "04:57", "iqamah": "05:40", "sunrise": "06:18" }, "dohar": { "begin": "12:18", "iqamah": "13:00" }, "asr": { "begin": "15:36", "iqamah": "16:35" }, "maghrib": { "begin": "18:08", "iqamah": "18:11" }, "isha": { "begin": "19:29", "iqamah": "19:50" } },
        "14": { "fajr": { "begin": "04:56", "iqamah": "05:40", "sunrise": "06:17" }, "dohar": { "begin": "12:18", "iqamah": "13:00" }, "asr": { "begin": "15:36", "iqamah": "16:35" }, "maghrib": { "begin": "18:08", "iqamah": "18:11" }, "isha": { "begin": "19:30", "iqamah": "19:50" } },
        "15": { "fajr": { "begin": "04:55", "iqamah": "05:40", "sunrise": "06:16" }, "dohar": { "begin": "12:18", "iqamah": "13:00" }, "asr": { "begin": "15:36", "iqamah": "16:35" }, "maghrib": { "begin": "18:09", "iqamah": "18:12" }, "isha": { "begin": "19:30", "iqamah": "19:50" } },
        "16": { "fajr": { "begin": "04:53", "iqamah": "05:40", "sunrise": "06:14" }, "dohar": { "begin": "12:17", "iqamah": "13:00" }, "asr": { "begin": "15:36", "iqamah": "16:35" }, "maghrib": { "begin": "18:10", "iqamah": "18:13" }, "isha": { "begin": "19:31", "iqamah": "19:50" } },
        "17": { "fajr": { "begin": "04:52", "iqamah": "05:40", "sunrise": "06:13" }, "dohar": { "begin": "12:17", "iqamah": "13:00" }, "asr": { "begin": "15:36", "iqamah": "16:35" }, "maghrib": { "begin": "18:10", "iqamah": "18:13" }, "isha": { "begin": "19:32", "iqamah": "19:50" } },
        "18": { "fajr": { "begin": "04:51", "iqamah": "05:40", "sunrise": "06:12" }, "dohar": { "begin": "12:16", "iqamah": "13:00" }, "asr": { "begin": "15:37", "iqamah": "16:35" }, "maghrib": { "begin": "18:11", "iqamah": "18:14" }, "isha": { "begin": "19:32", "iqamah": "19:50" } },
        "19": { "fajr": { "begin": "04:49", "iqamah": "05:40", "sunrise": "06:10" }, "dohar": { "begin": "12:16", "iqamah": "13:00" }, "asr": { "begin": "15:37", "iqamah": "16:35" }, "maghrib": { "begin": "18:11", "iqamah": "18:14" }, "isha": { "begin": "19:33", "iqamah": "19:50" } },
        "20": { "fajr": { "begin": "04:48", "iqamah": "05:40", "sunrise": "06:09" }, "dohar": { "begin": "12:16", "iqamah": "13:00" }, "asr": { "begin": "15:37", "iqamah": "16:35" }, "maghrib": { "begin": "18:12", "iqamah": "18:15" }, "isha": { "begin": "19:34", "iqamah": "19:50" } },
        "21": { "fajr": { "begin": "04:46", "iqamah": "05:25", "sunrise": "06:08" }, "dohar": { "begin": "12:15", "iqamah": "13:00" }, "asr": { "begin": "15:37", "iqamah": "16:35" }, "maghrib": { "begin": "18:13", "iqamah": "18:16" }, "isha": { "begin": "19:34", "iqamah": "20:00" } },
        "22": { "fajr": { "begin": "04:45", "iqamah": "05:25", "sunrise": "06:06" }, "dohar": { "begin": "12:15", "iqamah": "13:00" }, "asr": { "begin": "15:37", "iqamah": "16:35" }, "maghrib": { "begin": "18:13", "iqamah": "18:16" }, "isha": { "begin": "19:35", "iqamah": "20:00" } },
        "23": { "fajr": { "begin": "04:44", "iqamah": "05:25", "sunrise": "06:05" }, "dohar": { "begin": "12:15", "iqamah": "13:00" }, "asr": { "begin": "15:37", "iqamah": "16:35" }, "maghrib": { "begin": "18:14", "iqamah": "18:17" }, "isha": { "begin": "19:36", "iqamah": "20:00" } },
        "24": { "fajr": { "begin": "04:42", "iqamah": "05:25", "sunrise": "06:04" }, "dohar": { "begin": "12:14", "iqamah": "13:00" }, "asr": { "begin": "15:37", "iqamah": "16:35" }, "maghrib": { "begin": "18:14", "iqamah": "18:17" }, "isha": { "begin": "19:36", "iqamah": "20:00" } },
        "25": { "fajr": { "begin": "04:41", "iqamah": "05:25", "sunrise": "06:02" }, "dohar": { "begin": "12:14", "iqamah": "13:00" }, "asr": { "begin": "15:37", "iqamah": "17:10" }, "maghrib": { "begin": "18:15", "iqamah": "18:18" }, "isha": { "begin": "19:37", "iqamah": "20:00" } },
        "26": { "fajr": { "begin": "04:39", "iqamah": "05:25", "sunrise": "06:01" }, "dohar": { "begin": "12:14", "iqamah": "13:00" }, "asr": { "begin": "15:38", "iqamah": "17:10" }, "maghrib": { "begin": "18:16", "iqamah": "18:19" }, "isha": { "begin": "19:38", "iqamah": "20:00" } },
        "27": { "fajr": { "begin": "04:38", "iqamah": "05:25", "sunrise": "06:00" }, "dohar": { "begin": "12:13", "iqamah": "13:00" }, "asr": { "begin": "15:38", "iqamah": "17:10" }, "maghrib": { "begin": "18:16", "iqamah": "18:19" }, "isha": { "begin": "19:39", "iqamah": "20:00" } },
        "28": { "fajr": { "begin": "04:37", "iqamah": "05:25", "sunrise": "05:58" }, "dohar": { "begin": "12:13", "iqamah": "13:00" }, "asr": { "begin": "15:38", "iqamah": "17:10" }, "maghrib": { "begin": "18:17", "iqamah": "18:20" }, "isha": { "begin": "19:39", "iqamah": "20:00" } },
        "29": { "fajr": { "begin": "04:35", "iqamah": "05:25", "sunrise": "05:57" }, "dohar": { "begin": "12:13", "iqamah": "13:00" }, "asr": { "begin": "15:38", "iqamah": "17:10" }, "maghrib": { "begin": "18:18", "iqamah": "18:21" }, "isha": { "begin": "19:40", "iqamah": "20:00" } },
        "30": { "fajr": { "begin": "04:34", "iqamah": "05:25", "sunrise": "05:56" }, "dohar": { "begin": "12:12", "iqamah": "13:00" }, "asr": { "begin": "15:38", "iqamah": "17:10" }, "maghrib": { "begin": "18:18", "iqamah": "18:21" }, "isha": { "begin": "19:41", "iqamah": "20:00" } },
    },
    "9": {
        "1": { "fajr": { "begin": "04:32", "iqamah": "05:15", "sunrise": "05:55" }, "dohar": { "begin": "12:12", "iqamah": "13:00" }, "asr": { "begin": "15:38", "iqamah": "17:10" }, "maghrib": { "begin": "18:19", "iqamah": "18:22" }, "isha": { "begin": "19:42", "iqamah": "20:10" } },
        "2": { "fajr": { "begin": "04:31", "iqamah": "05:15", "sunrise": "05:53" }, "dohar": { "begin": "12:12", "iqamah": "13:00" }, "asr": { "begin": "15:38", "iqamah": "17:10" }, "maghrib": { "begin": "18:20", "iqamah": "18:23" }, "isha": { "begin": "19:42", "iqamah": "20:10" } },
        "3": { "fajr": { "begin": "04:30", "iqamah": "05:15", "sunrise": "05:52" }, "dohar": { "begin": "12:11", "iqamah": "13:00" }, "asr": { "begin": "15:38", "iqamah": "17:10" }, "maghrib": { "begin": "18:20", "iqamah": "18:23" }, "isha": { "begin": "19:43", "iqamah": "20:10" } },
        "4": { "fajr": { "begin": "04:28", "iqamah": "05:15", "sunrise": "05:51" }, "dohar": { "begin": "12:11", "iqamah": "13:00" }, "asr": { "begin": "15:38", "iqamah": "17:10" }, "maghrib": { "begin": "18:21", "iqamah": "18:24" }, "isha": { "begin": "19:44", "iqamah": "20:10" } },
        "5": { "fajr": { "begin": "04:27", "iqamah": "05:15", "sunrise": "05:49" }, "dohar": { "begin": "12:11", "iqamah": "13:00" }, "asr": { "begin": "15:38", "iqamah": "17:10" }, "maghrib": { "begin": "18:22", "iqamah": "18:25" }, "isha": { "begin": "19:45", "iqamah": "20:10" } },
        "6": { "fajr": { "begin": "04:25", "iqamah": "05:15", "sunrise": "05:48" }, "dohar": { "begin": "12:10", "iqamah": "13:00" }, "asr": { "begin": "15:38", "iqamah": "17:10" }, "maghrib": { "begin": "18:22", "iqamah": "18:25" }, "isha": { "begin": "19:46", "iqamah": "20:10" } },
        "7": { "fajr": { "begin": "04:24", "iqamah": "05:15", "sunrise": "05:47" }, "dohar": { "begin": "12:10", "iqamah": "13:00" }, "asr": { "begin": "15:38", "iqamah": "17:10" }, "maghrib": { "begin": "18:23", "iqamah": "18:26" }, "isha": { "begin": "19:46", "iqamah": "20:10" } },
        "8": { "fajr": { "begin": "04:22", "iqamah": "05:15", "sunrise": "05:46" }, "dohar": { "begin": "12:10", "iqamah": "13:00" }, "asr": { "begin": "15:39", "iqamah": "17:10" }, "maghrib": { "begin": "18:24", "iqamah": "18:27" }, "isha": { "begin": "19:47", "iqamah": "20:10" } },
        "9": { "fajr": { "begin": "04:21", "iqamah": "05:15", "sunrise": "05:44" }, "dohar": { "begin": "12:10", "iqamah": "13:00" }, "asr": { "begin": "15:39", "iqamah": "17:10" }, "maghrib": { "begin": "18:24", "iqamah": "18:27" }, "isha": { "begin": "19:48", "iqamah": "20:10" } },
        "10": { "fajr": { "begin": "04:20", "iqamah": "05:15", "sunrise": "05:43" }, "dohar": { "begin": "12:09", "iqamah": "13:00" }, "asr": { "begin": "15:39", "iqamah": "17:10" }, "maghrib": { "begin": "18:25", "iqamah": "18:28" }, "isha": { "begin": "19:49", "iqamah": "20:10" } },
        "11": { "fajr": { "begin": "04:18", "iqamah": "05:00", "sunrise": "05:42" }, "dohar": { "begin": "12:09", "iqamah": "13:00" }, "asr": { "begin": "15:39", "iqamah": "17:10" }, "maghrib": { "begin": "18:26", "iqamah": "18:29" }, "isha": { "begin": "19:50", "iqamah": "20:10" } },
        "12": { "fajr": { "begin": "04:17", "iqamah": "05:00", "sunrise": "05:41" }, "dohar": { "begin": "12:09", "iqamah": "13:00" }, "asr": { "begin": "15:39", "iqamah": "17:10" }, "maghrib": { "begin": "18:26", "iqamah": "18:29" }, "isha": { "begin": "19:51", "iqamah": "20:10" } },
        "13": { "fajr": { "begin": "04:15", "iqamah": "05:00", "sunrise": "05:39" }, "dohar": { "begin": "12:09", "iqamah": "13:00" }, "asr": { "begin": "15:39", "iqamah": "17:10" }, "maghrib": { "begin": "18:27", "iqamah": "18:30" }, "isha": { "begin": "19:52", "iqamah": "20:10" } },
        "14": { "fajr": { "begin": "04:14", "iqamah": "05:00", "sunrise": "05:38" }, "dohar": { "begin": "12:08", "iqamah": "13:00" }, "asr": { "begin": "15:39", "iqamah": "17:10" }, "maghrib": { "begin": "18:28", "iqamah": "18:31" }, "isha": { "begin": "19:52", "iqamah": "20:10" } },
        "15": { "fajr": { "begin": "04:13", "iqamah": "05:00", "sunrise": "05:37" }, "dohar": { "begin": "12:08", "iqamah": "13:00" }, "asr": { "begin": "15:39", "iqamah": "17:10" }, "maghrib": { "begin": "18:29", "iqamah": "18:32" }, "isha": { "begin": "19:53", "iqamah": "20:10" } },
        "16": { "fajr": { "begin": "04:11", "iqamah": "05:00", "sunrise": "05:36" }, "dohar": { "begin": "12:08", "iqamah": "13:00" }, "asr": { "begin": "15:39", "iqamah": "17:10" }, "maghrib": { "begin": "18:29", "iqamah": "18:32" }, "isha": { "begin": "19:54", "iqamah": "20:10" } },
        "17": { "fajr": { "begin": "04:10", "iqamah": "05:00", "sunrise": "05:35" }, "dohar": { "begin": "12:08", "iqamah": "13:00" }, "asr": { "begin": "15:39", "iqamah": "17:10" }, "maghrib": { "begin": "18:30", "iqamah": "18:33" }, "isha": { "begin": "19:55", "iqamah": "20:10" } },
        "18": { "fajr": { "begin": "04:09", "iqamah": "05:00", "sunrise": "05:34" }, "dohar": { "begin": "12:07", "iqamah": "13:00" }, "asr": { "begin": "15:39", "iqamah": "17:10" }, "maghrib": { "begin": "18:31", "iqamah": "18:34" }, "isha": { "begin": "19:56", "iqamah": "20:10" } },
        "19": { "fajr": { "begin": "04:07", "iqamah": "05:00", "sunrise": "05:32" }, "dohar": { "begin": "12:07", "iqamah": "13:00" }, "asr": { "begin": "15:39", "iqamah": "17:10" }, "maghrib": { "begin": "18:31", "iqamah": "18:34" }, "isha": { "begin": "19:57", "iqamah": "20:10" } },
        "20": { "fajr": { "begin": "04:06", "iqamah": "05:00", "sunrise": "05:31" }, "dohar": { "begin": "12:07", "iqamah": "13:00" }, "asr": { "begin": "15:39", "iqamah": "17:10" }, "maghrib": { "begin": "18:32", "iqamah": "18:35" }, "isha": { "begin": "19:58", "iqamah": "20:10" } },
        "21": { "fajr": { "begin": "04:05", "iqamah": "04:50", "sunrise": "05:30" }, "dohar": { "begin": "12:07", "iqamah": "13:00" }, "asr": { "begin": "15:39", "iqamah": "17:10" }, "maghrib": { "begin": "18:33", "iqamah": "18:36" }, "isha": { "begin": "19:59", "iqamah": "20:20" } },
        "22": { "fajr": { "begin": "04:03", "iqamah": "04:50", "sunrise": "05:29" }, "dohar": { "begin": "12:07", "iqamah": "13:00" }, "asr": { "begin": "15:39", "iqamah": "17:10" }, "maghrib": { "begin": "18:34", "iqamah": "18:37" }, "isha": { "begin": "20:00", "iqamah": "20:20" } },
        "23": { "fajr": { "begin": "04:02", "iqamah": "04:50", "sunrise": "05:28" }, "dohar": { "begin": "12:07", "iqamah": "13:00" }, "asr": { "begin": "15:39", "iqamah": "17:10" }, "maghrib": { "begin": "18:35", "iqamah": "18:38" }, "isha": { "begin": "20:01", "iqamah": "20:20" } },
        "24": { "fajr": { "begin": "04:01", "iqamah": "04:50", "sunrise": "05:27" }, "dohar": { "begin": "12:06", "iqamah": "13:00" }, "asr": { "begin": "15:39", "iqamah": "17:10" }, "maghrib": { "begin": "18:35", "iqamah": "18:38" }, "isha": { "begin": "20:02", "iqamah": "20:20" } },
        "25": { "fajr": { "begin": "03:59", "iqamah": "04:50", "sunrise": "05:26" }, "dohar": { "begin": "12:06", "iqamah": "13:00" }, "asr": { "begin": "15:40", "iqamah": "17:10" }, "maghrib": { "begin": "18:36", "iqamah": "18:39" }, "isha": { "begin": "20:03", "iqamah": "20:20" } },
        "26": { "fajr": { "begin": "03:58", "iqamah": "04:50", "sunrise": "05:25" }, "dohar": { "begin": "12:06", "iqamah": "13:00" }, "asr": { "begin": "15:40", "iqamah": "17:10" }, "maghrib": { "begin": "18:37", "iqamah": "18:40" }, "isha": { "begin": "20:04", "iqamah": "20:20" } },
        "27": { "fajr": { "begin": "03:57", "iqamah": "04:50", "sunrise": "05:24" }, "dohar": { "begin": "12:06", "iqamah": "13:00" }, "asr": { "begin": "15:40", "iqamah": "17:10" }, "maghrib": { "begin": "18:38", "iqamah": "18:41" }, "isha": { "begin": "20:05", "iqamah": "20:20" } },
        "28": { "fajr": { "begin": "03:55", "iqamah": "04:50", "sunrise": "05:23" }, "dohar": { "begin": "12:06", "iqamah": "13:00" }, "asr": { "begin": "15:40", "iqamah": "17:10" }, "maghrib": { "begin": "18:39", "iqamah": "18:42" }, "isha": { "begin": "20:07", "iqamah": "20:20" } },
        "29": { "fajr": { "begin": "03:54", "iqamah": "04:50", "sunrise": "05:22" }, "dohar": { "begin": "12:06", "iqamah": "13:00" }, "asr": { "begin": "15:40", "iqamah": "17:10" }, "maghrib": { "begin": "18:39", "iqamah": "18:42" }, "isha": { "begin": "20:08", "iqamah": "20:20" } },
        "30": { "fajr": { "begin": "03:53", "iqamah": "04:50", "sunrise": "05:21" }, "dohar": { "begin": "12:06", "iqamah": "13:00" }, "asr": { "begin": "15:40", "iqamah": "17:10" }, "maghrib": { "begin": "18:40", "iqamah": "18:43" }, "isha": { "begin": "20:09", "iqamah": "20:20" } },
        "31": { "fajr": { "begin": "03:52", "iqamah": "04:50", "sunrise": "05:20" }, "dohar": { "begin": "12:06", "iqamah": "13:00" }, "asr": { "begin": "15:40", "iqamah": "17:10" }, "maghrib": { "begin": "18:41", "iqamah": "18:44" }, "isha": { "begin": "20:10", "iqamah": "20:20" } },
    },
    "10": {
        "1": { "fajr": { "begin": "03:51", "iqamah": "04:40", "sunrise": "05:19" }, "dohar": { "begin": "12:06", "iqamah": "13:00" }, "asr": { "begin": "15:40", "iqamah": "17:10" }, "maghrib": { "begin": "18:42", "iqamah": "18:45" }, "isha": { "begin": "20:11", "iqamah": "20:30" } },
        "2": { "fajr": { "begin": "03:49", "iqamah": "04:40", "sunrise": "05:18" }, "dohar": { "begin": "12:06", "iqamah": "13:00" }, "asr": { "begin": "15:40", "iqamah": "17:10" }, "maghrib": { "begin": "18:43", "iqamah": "18:46" }, "isha": { "begin": "20:12", "iqamah": "20:30" } },
        "3": { "fajr": { "begin": "03:48", "iqamah": "04:40", "sunrise": "05:17" }, "dohar": { "begin": "12:06", "iqamah": "13:00" }, "asr": { "begin": "15:40", "iqamah": "17:10" }, "maghrib": { "begin": "18:44", "iqamah": "18:47" }, "isha": { "begin": "20:13", "iqamah": "20:30" } },
        "4": { "fajr": { "begin": "03:47", "iqamah": "04:40", "sunrise": "05:17" }, "dohar": { "begin": "12:06", "iqamah": "13:00" }, "asr": { "begin": "15:40", "iqamah": "17:10" }, "maghrib": { "begin": "18:44", "iqamah": "18:47" }, "isha": { "begin": "20:14", "iqamah": "20:30" } },
        "5": { "fajr": { "begin": "03:46", "iqamah": "04:40", "sunrise": "05:16" }, "dohar": { "begin": "12:06", "iqamah": "13:00" }, "asr": { "begin": "15:41", "iqamah": "17:10" }, "maghrib": { "begin": "18:45", "iqamah": "18:48" }, "isha": { "begin": "20:16", "iqamah": "20:30" } },
        "6": { "fajr": { "begin": "03:45", "iqamah": "04:40", "sunrise": "05:15" }, "dohar": { "begin": "12:06", "iqamah": "13:00" }, "asr": { "begin": "15:41", "iqamah": "17:10" }, "maghrib": { "begin": "18:46", "iqamah": "18:49" }, "isha": { "begin": "20:17", "iqamah": "20:30" } },
        "7": { "fajr": { "begin": "03:44", "iqamah": "04:40", "sunrise": "05:14" }, "dohar": { "begin": "12:06", "iqamah": "13:00" }, "asr": { "begin": "15:41", "iqamah": "17:10" }, "maghrib": { "begin": "18:47", "iqamah": "18:50" }, "isha": { "begin": "20:18", "iqamah": "20:30" } },
        "8": { "fajr": { "begin": "03:43", "iqamah": "04:40", "sunrise": "05:13" }, "dohar": { "begin": "12:06", "iqamah": "13:00" }, "asr": { "begin": "15:41", "iqamah": "17:10" }, "maghrib": { "begin": "18:48", "iqamah": "18:51" }, "isha": { "begin": "20:19", "iqamah": "20:30" } },
        "9": { "fajr": { "begin": "03:42", "iqamah": "04:40", "sunrise": "05:13" }, "dohar": { "begin": "12:06", "iqamah": "13:00" }, "asr": { "begin": "15:41", "iqamah": "17:10" }, "maghrib": { "begin": "18:49", "iqamah": "18:52" }, "isha": { "begin": "20:20", "iqamah": "20:30" } },
        "10": { "fajr": { "begin": "03:41", "iqamah": "04:40", "sunrise": "05:12" }, "dohar": { "begin": "12:06", "iqamah": "13:00" }, "asr": { "begin": "15:41", "iqamah": "17:10" }, "maghrib": { "begin": "18:50", "iqamah": "18:53" }, "isha": { "begin": "20:21", "iqamah": "20:30" } },
        "11": { "fajr": { "begin": "03:40", "iqamah": "04:40", "sunrise": "05:11" }, "dohar": { "begin": "12:06", "iqamah": "13:00" }, "asr": { "begin": "15:41", "iqamah": "17:10" }, "maghrib": { "begin": "18:51", "iqamah": "18:54" }, "isha": { "begin": "20:23", "iqamah": "20:40" } },
        "12": { "fajr": { "begin": "03:39", "iqamah": "04:40", "sunrise": "05:10" }, "dohar": { "begin": "12:06", "iqamah": "13:00" }, "asr": { "begin": "15:42", "iqamah": "17:10" }, "maghrib": { "begin": "18:52", "iqamah": "18:55" }, "isha": { "begin": "20:24", "iqamah": "20:40" } },
        "13": { "fajr": { "begin": "03:38", "iqamah": "04:40", "sunrise": "05:10" }, "dohar": { "begin": "12:06", "iqamah": "13:00" }, "asr": { "begin": "15:42", "iqamah": "17:10" }, "maghrib": { "begin": "18:52", "iqamah": "18:55" }, "isha": { "begin": "20:25", "iqamah": "20:40" } },
        "14": { "fajr": { "begin": "03:37", "iqamah": "04:40", "sunrise": "05:09" }, "dohar": { "begin": "12:07", "iqamah": "13:00" }, "asr": { "begin": "15:42", "iqamah": "17:10" }, "maghrib": { "begin": "18:53", "iqamah": "18:56" }, "isha": { "begin": "20:26", "iqamah": "20:40" } },
        "15": { "fajr": { "begin": "03:36", "iqamah": "04:40", "sunrise": "05:09" }, "dohar": { "begin": "12:07", "iqamah": "13:00" }, "asr": { "begin": "15:42", "iqamah": "17:10" }, "maghrib": { "begin": "18:54", "iqamah": "18:57" }, "isha": { "begin": "20:27", "iqamah": "20:40" } },
        "16": { "fajr": { "begin": "03:35", "iqamah": "04:30", "sunrise": "05:08" }, "dohar": { "begin": "12:07", "iqamah": "13:00" }, "asr": { "begin": "15:42", "iqamah": "17:10" }, "maghrib": { "begin": "18:55", "iqamah": "18:58" }, "isha": { "begin": "20:29", "iqamah": "20:40" } },
        "17": { "fajr": { "begin": "03:34", "iqamah": "04:30", "sunrise": "05:08" }, "dohar": { "begin": "12:07", "iqamah": "13:00" }, "asr": { "begin": "15:43", "iqamah": "17:10" }, "maghrib": { "begin": "18:56", "iqamah": "18:59" }, "isha": { "begin": "20:30", "iqamah": "20:40" } },
        "18": { "fajr": { "begin": "03:34", "iqamah": "04:30", "sunrise": "05:07" }, "dohar": { "begin": "12:07", "iqamah": "13:00" }, "asr": { "begin": "15:43", "iqamah": "17:10" }, "maghrib": { "begin": "18:57", "iqamah": "19:00" }, "isha": { "begin": "20:31", "iqamah": "20:40" } },
        "19": { "fajr": { "begin": "03:33", "iqamah": "04:30", "sunrise": "05:07" }, "dohar": { "begin": "12:07", "iqamah": "13:00" }, "asr": { "begin": "15:43", "iqamah": "17:10" }, "maghrib": { "begin": "18:58", "iqamah": "19:01" }, "isha": { "begin": "20:32", "iqamah": "20:40" } },
        "20": { "fajr": { "begin": "03:32", "iqamah": "04:30", "sunrise": "05:06" }, "dohar": { "begin": "12:08", "iqamah": "13:00" }, "asr": { "begin": "15:43", "iqamah": "17:10" }, "maghrib": { "begin": "18:59", "iqamah": "19:02" }, "isha": { "begin": "20:33", "iqamah": "20:40" } },
        "21": { "fajr": { "begin": "03:31", "iqamah": "04:30", "sunrise": "05:06" }, "dohar": { "begin": "12:08", "iqamah": "13:00" }, "asr": { "begin": "15:44", "iqamah": "17:10" }, "maghrib": { "begin": "19:00", "iqamah": "19:03" }, "isha": { "begin": "20:35", "iqamah": "20:50" } },
        "22": { "fajr": { "begin": "03:31", "iqamah": "04:30", "sunrise": "05:05" }, "dohar": { "begin": "12:08", "iqamah": "13:00" }, "asr": { "begin": "15:44", "iqamah": "17:10" }, "maghrib": { "begin": "19:00", "iqamah": "19:03" }, "isha": { "begin": "20:36", "iqamah": "20:50" } },
        "23": { "fajr": { "begin": "03:30", "iqamah": "04:30", "sunrise": "05:05" }, "dohar": { "begin": "12:08", "iqamah": "13:00" }, "asr": { "begin": "15:44", "iqamah": "17:10" }, "maghrib": { "begin": "19:01", "iqamah": "19:04" }, "isha": { "begin": "20:37", "iqamah": "20:50" } },
        "24": { "fajr": { "begin": "03:29", "iqamah": "04:30", "sunrise": "05:05" }, "dohar": { "begin": "12:09", "iqamah": "13:00" }, "asr": { "begin": "15:44", "iqamah": "17:10" }, "maghrib": { "begin": "19:02", "iqamah": "19:05" }, "isha": { "begin": "20:38", "iqamah": "20:50" } },
        "25": { "fajr": { "begin": "03:29", "iqamah": "04:30", "sunrise": "05:04" }, "dohar": { "begin": "12:09", "iqamah": "13:00" }, "asr": { "begin": "15:45", "iqamah": "17:10" }, "maghrib": { "begin": "19:03", "iqamah": "19:06" }, "isha": { "begin": "20:39", "iqamah": "20:50" } },
        "26": { "fajr": { "begin": "03:28", "iqamah": "04:30", "sunrise": "05:04" }, "dohar": { "begin": "12:09", "iqamah": "13:00" }, "asr": { "begin": "15:45", "iqamah": "17:10" }, "maghrib": { "begin": "19:04", "iqamah": "19:07" }, "isha": { "begin": "20:40", "iqamah": "20:55" } },
        "27": { "fajr": { "begin": "03:28", "iqamah": "04:30", "sunrise": "05:04" }, "dohar": { "begin": "12:10", "iqamah": "13:00" }, "asr": { "begin": "15:45", "iqamah": "17:10" }, "maghrib": { "begin": "19:05", "iqamah": "19:08" }, "isha": { "begin": "20:41", "iqamah": "20:55" } },
        "28": { "fajr": { "begin": "03:27", "iqamah": "04:30", "sunrise": "05:04" }, "dohar": { "begin": "12:10", "iqamah": "13:00" }, "asr": { "begin": "15:46", "iqamah": "17:10" }, "maghrib": { "begin": "19:06", "iqamah": "19:09" }, "isha": { "begin": "20:43", "iqamah": "20:55" } },
        "29": { "fajr": { "begin": "03:27", "iqamah": "04:30", "sunrise": "05:03" }, "dohar": { "begin": "12:10", "iqamah": "13:00" }, "asr": { "begin": "15:46", "iqamah": "17:10" }, "maghrib": { "begin": "19:07", "iqamah": "19:10" }, "isha": { "begin": "20:44", "iqamah": "20:55" } },
        "30": { "fajr": { "begin": "03:27", "iqamah": "04:30", "sunrise": "05:03" }, "dohar": { "begin": "12:11", "iqamah": "13:00" }, "asr": { "begin": "15:46", "iqamah": "17:10" }, "maghrib": { "begin": "19:08", "iqamah": "19:11" }, "isha": { "begin": "20:45", "iqamah": "20:55" } },
    },
    "11": {
        "1": { "fajr": { "begin": "03:26", "iqamah": "04:30", "sunrise": "05:03" }, "dohar": { "begin": "12:11", "iqamah": "13:00" }, "asr": { "begin": "15:47", "iqamah": "17:15" }, "maghrib": { "begin": "19:08", "iqamah": "19:11" }, "isha": { "begin": "20:46", "iqamah": "21:00" } },
        "2": { "fajr": { "begin": "03:26", "iqamah": "04:30", "sunrise": "05:03" }, "dohar": { "begin": "12:11", "iqamah": "13:00" }, "asr": { "begin": "15:47", "iqamah": "17:15" }, "maghrib": { "begin": "19:09", "iqamah": "19:12" }, "isha": { "begin": "20:47", "iqamah": "21:00" } },
        "3": { "fajr": { "begin": "03:26", "iqamah": "04:30", "sunrise": "05:03" }, "dohar": { "begin": "12:12", "iqamah": "13:00" }, "asr": { "begin": "15:47", "iqamah": "17:15" }, "maghrib": { "begin": "19:10", "iqamah": "19:13" }, "isha": { "begin": "20:48", "iqamah": "21:00" } },
        "4": { "fajr": { "begin": "03:25", "iqamah": "04:30", "sunrise": "05:03" }, "dohar": { "begin": "12:12", "iqamah": "13:00" }, "asr": { "begin": "15:48", "iqamah": "17:15" }, "maghrib": { "begin": "19:11", "iqamah": "19:14" }, "isha": { "begin": "20:49", "iqamah": "21:00" } },
        "5": { "fajr": { "begin": "03:25", "iqamah": "04:30", "sunrise": "05:03" }, "dohar": { "begin": "12:13", "iqamah": "13:00" }, "asr": { "begin": "15:48", "iqamah": "17:15" }, "maghrib": { "begin": "19:12", "iqamah": "19:15" }, "isha": { "begin": "20:50", "iqamah": "21:00" } },
        "6": { "fajr": { "begin": "03:25", "iqamah": "04:30", "sunrise": "05:03" }, "dohar": { "begin": "12:13", "iqamah": "13:00" }, "asr": { "begin": "15:48", "iqamah": "17:15" }, "maghrib": { "begin": "19:12", "iqamah": "19:15" }, "isha": { "begin": "20:51", "iqamah": "21:10" } },
        "7": { "fajr": { "begin": "03:25", "iqamah": "04:30", "sunrise": "05:03" }, "dohar": { "begin": "12:13", "iqamah": "13:00" }, "asr": { "begin": "15:49", "iqamah": "17:15" }, "maghrib": { "begin": "19:13", "iqamah": "19:16" }, "isha": { "begin": "20:52", "iqamah": "21:10" } },
        "8": { "fajr": { "begin": "03:25", "iqamah": "04:30", "sunrise": "05:03" }, "dohar": { "begin": "12:14", "iqamah": "13:00" }, "asr": { "begin": "15:49", "iqamah": "17:15" }, "maghrib": { "begin": "19:14", "iqamah": "19:17" }, "isha": { "begin": "20:53", "iqamah": "21:10" } },
        "9": { "fajr": { "begin": "03:25", "iqamah": "04:30", "sunrise": "05:03" }, "dohar": { "begin": "12:14", "iqamah": "13:00" }, "asr": { "begin": "15:50", "iqamah": "17:15" }, "maghrib": { "begin": "19:15", "iqamah": "19:18" }, "isha": { "begin": "20:54", "iqamah": "21:10" } },
        "10": { "fajr": { "begin": "03:25", "iqamah": "04:30", "sunrise": "05:03" }, "dohar": { "begin": "12:15", "iqamah": "13:00" }, "asr": { "begin": "15:50", "iqamah": "17:15" }, "maghrib": { "begin": "19:15", "iqamah": "19:18" }, "isha": { "begin": "20:55", "iqamah": "21:10" } },
        "11": { "fajr": { "begin": "03:25", "iqamah": "04:30", "sunrise": "05:03" }, "dohar": { "begin": "12:15", "iqamah": "13:00" }, "asr": { "begin": "15:51", "iqamah": "17:30" }, "maghrib": { "begin": "19:16", "iqamah": "19:19" }, "isha": { "begin": "20:56", "iqamah": "21:10" } },
        "12": { "fajr": { "begin": "03:25", "iqamah": "04:30", "sunrise": "05:04" }, "dohar": { "begin": "12:16", "iqamah": "13:00" }, "asr": { "begin": "15:51", "iqamah": "17:30" }, "maghrib": { "begin": "19:17", "iqamah": "19:20" }, "isha": { "begin": "20:56", "iqamah": "21:10" } },
        "13": { "fajr": { "begin": "03:25", "iqamah": "04:30", "sunrise": "05:04" }, "dohar": { "begin": "12:16", "iqamah": "13:00" }, "asr": { "begin": "15:51", "iqamah": "17:30" }, "maghrib": { "begin": "19:18", "iqamah": "19:21" }, "isha": { "begin": "20:57", "iqamah": "21:10" } },
        "14": { "fajr": { "begin": "03:25", "iqamah": "04:30", "sunrise": "05:04" }, "dohar": { "begin": "12:17", "iqamah": "13:00" }, "asr": { "begin": "15:52", "iqamah": "17:30" }, "maghrib": { "begin": "19:18", "iqamah": "19:21" }, "isha": { "begin": "20:58", "iqamah": "21:10" } },
        "15": { "fajr": { "begin": "03:25", "iqamah": "04:35", "sunrise": "05:04" }, "dohar": { "begin": "12:17", "iqamah": "13:00" }, "asr": { "begin": "15:52", "iqamah": "17:30" }, "maghrib": { "begin": "19:19", "iqamah": "19:22" }, "isha": { "begin": "20:59", "iqamah": "21:10" } },
        "16": { "fajr": { "begin": "03:26", "iqamah": "04:35", "sunrise": "05:05" }, "dohar": { "begin": "12:18", "iqamah": "13:00" }, "asr": { "begin": "15:53", "iqamah": "17:30" }, "maghrib": { "begin": "19:20", "iqamah": "19:23" }, "isha": { "begin": "20:59", "iqamah": "21:10" } },
        "17": { "fajr": { "begin": "03:26", "iqamah": "04:35", "sunrise": "05:05" }, "dohar": { "begin": "12:18", "iqamah": "13:00" }, "asr": { "begin": "15:53", "iqamah": "17:30" }, "maghrib": { "begin": "19:20", "iqamah": "19:23" }, "isha": { "begin": "21:00", "iqamah": "21:15" } },
        "18": { "fajr": { "begin": "03:26", "iqamah": "04:35", "sunrise": "05:05" }, "dohar": { "begin": "12:19", "iqamah": "13:00" }, "asr": { "begin": "15:54", "iqamah": "17:30" }, "maghrib": { "begin": "19:21", "iqamah": "19:24" }, "isha": { "begin": "21:01", "iqamah": "21:15" } },
        "19": { "fajr": { "begin": "03:27", "iqamah": "04:35", "sunrise": "05:06" }, "dohar": { "begin": "12:19", "iqamah": "13:00" }, "asr": { "begin": "15:54", "iqamah": "17:30" }, "maghrib": { "begin": "19:21", "iqamah": "19:24" }, "isha": { "begin": "21:01", "iqamah": "21:15" } },
        "20": { "fajr": { "begin": "03:27", "iqamah": "04:35", "sunrise": "05:06" }, "dohar": { "begin": "12:20", "iqamah": "13:00" }, "asr": { "begin": "15:55", "iqamah": "17:30" }, "maghrib": { "begin": "19:22", "iqamah": "19:25" }, "isha": { "begin": "21:02", "iqamah": "21:15" } },
        "21": { "fajr": { "begin": "03:28", "iqamah": "04:35", "sunrise": "05:07" }, "dohar": { "begin": "12:20", "iqamah": "13:00" }, "asr": { "begin": "15:55", "iqamah": "17:30" }, "maghrib": { "begin": "19:22", "iqamah": "19:25" }, "isha": { "begin": "21:02", "iqamah": "21:15" } },
        "22": { "fajr": { "begin": "03:28", "iqamah": "04:35", "sunrise": "05:07" }, "dohar": { "begin": "12:21", "iqamah": "13:00" }, "asr": { "begin": "15:56", "iqamah": "17:30" }, "maghrib": { "begin": "19:23", "iqamah": "19:26" }, "isha": { "begin": "21:03", "iqamah": "21:15" } },
        "23": { "fajr": { "begin": "03:29", "iqamah": "04:35", "sunrise": "05:08" }, "dohar": { "begin": "12:21", "iqamah": "13:00" }, "asr": { "begin": "15:56", "iqamah": "17:30" }, "maghrib": { "begin": "19:23", "iqamah": "19:26" }, "isha": { "begin": "21:03", "iqamah": "21:15" } },
        "24": { "fajr": { "begin": "03:29", "iqamah": "04:35", "sunrise": "05:08" }, "dohar": { "begin": "12:22", "iqamah": "13:00" }, "asr": { "begin": "15:57", "iqamah": "17:30" }, "maghrib": { "begin": "19:24", "iqamah": "19:27" }, "isha": { "begin": "21:04", "iqamah": "21:15" } },
        "25": { "fajr": { "begin": "03:30", "iqamah": "04:35", "sunrise": "05:09" }, "dohar": { "begin": "12:22", "iqamah": "13:00" }, "asr": { "begin": "15:57", "iqamah": "17:30" }, "maghrib": { "begin": "19:24", "iqamah": "19:27" }, "isha": { "begin": "21:04", "iqamah": "21:15" } },
        "26": { "fajr": { "begin": "03:31", "iqamah": "04:35", "sunrise": "05:09" }, "dohar": { "begin": "12:23", "iqamah": "13:00" }, "asr": { "begin": "15:58", "iqamah": "17:30" }, "maghrib": { "begin": "19:25", "iqamah": "19:28" }, "isha": { "begin": "21:04", "iqamah": "21:15" } },
        "27": { "fajr": { "begin": "03:31", "iqamah": "04:35", "sunrise": "05:10" }, "dohar": { "begin": "12:23", "iqamah": "13:00" }, "asr": { "begin": "15:58", "iqamah": "17:30" }, "maghrib": { "begin": "19:25", "iqamah": "19:28" }, "isha": { "begin": "21:05", "iqamah": "21:15" } },
        "28": { "fajr": { "begin": "03:32", "iqamah": "04:35", "sunrise": "05:11" }, "dohar": { "begin": "12:24", "iqamah": "13:00" }, "asr": { "begin": "15:59", "iqamah": "17:30" }, "maghrib": { "begin": "19:25", "iqamah": "19:28" }, "isha": { "begin": "21:05", "iqamah": "21:15" } },
        "29": { "fajr": { "begin": "03:33", "iqamah": "04:35", "sunrise": "05:11" }, "dohar": { "begin": "12:24", "iqamah": "13:00" }, "asr": { "begin": "15:59", "iqamah": "17:30" }, "maghrib": { "begin": "19:26", "iqamah": "19:29" }, "isha": { "begin": "21:05", "iqamah": "21:15" } },
        "30": { "fajr": { "begin": "03:34", "iqamah": "04:35", "sunrise": "05:12" }, "dohar": { "begin": "12:25", "iqamah": "13:00" }, "asr": { "begin": "16:00", "iqamah": "17:30" }, "maghrib": { "begin": "19:26", "iqamah": "19:29" }, "isha": { "begin": "21:05", "iqamah": "21:15" } },
        "31": { "fajr": { "begin": "03:34", "iqamah": "04:35", "sunrise": "05:13" }, "dohar": { "begin": "12:25", "iqamah": "13:00" }, "asr": { "begin": "16:00", "iqamah": "17:30" }, "maghrib": { "begin": "19:26", "iqamah": "19:29" }, "isha": { "begin": "21:06", "iqamah": "21:15" } },
    }
}