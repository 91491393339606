const D2R = Math.PI / 180;
const R2D = 180 / Math.PI;
const zenith = 90.83333333333333;

export function sunriseset(dayOfYear, la, lo) {
  // convert the longitude to hour value and calculate an approximate time
  const lnHour = lo / 15; //15 degrees of longitude is equal to 1 hour difference
  const sunriseT = dayOfYear + (6 - lnHour) / 24;
  const sunsetT = dayOfYear + (18 - lnHour) / 24;

  const { cosH: sunriseCosH, RA: sunriseRA } = hourAngle(sunriseT, la);
  const { cosH: sunsetCosH, RA: sunsetRA } = hourAngle(sunsetT, la);
  const sunriseH = 360 - R2D * Math.acos(sunriseCosH);
  const sunsetH = R2D * Math.acos(sunsetCosH);

  const sunriseMillis = sunTimeMillis(sunriseH, sunriseRA, sunriseT, lnHour)
  const sunsetMillis = sunTimeMillis(sunsetH, sunsetRA, sunsetT, lnHour)
  
    return {sunriseMillis, sunsetMillis};
}

export function hourAngle(t, la) {
  //calculate the Sun's mean anomaly
  const M = 0.9856 * t - 3.289;

  //calculate the Sun's true longitude
  let L =
    M + 1.916 * Math.sin(M * D2R) + 0.02 * Math.sin(2 * M * D2R) + 282.634;
  if (L > 360) {
    L = L - 360;
  } else if (L < 0) {
    L = L + 360;
  }

  //calculate the Sun's right ascension
  let RA = R2D * Math.atan(0.91764 * Math.tan(L * D2R));
  if (RA > 360) {
    RA = RA - 360;
  } else if (RA < 0) {
    RA = RA + 360;
  }

  //right ascension value needs to be in the same qua
  const Lquadrant = Math.floor(L / 90) * 90;
  const RAquadrant = Math.floor(RA / 90) * 90;
  RA = RA + (Lquadrant - RAquadrant);

  //right ascension value needs to be converted into hours
  RA = RA / 15; //15 degrees of longitude is equal to 1 hour difference

  //calculate the Sun's declination
  const sinDec = 0.39782 * Math.sin(L * D2R);
  const cosDec = Math.cos(Math.asin(sinDec));

  //Hyperbolic cosine
  const cosH =
    (Math.cos(zenith * D2R) - sinDec * Math.sin(la * D2R)) /
    (cosDec * Math.cos(la * D2R));

  return { cosH, RA };
}

export function sunTimeMillis(H, RA, t, lnHour) {
  H = H / 15;

  //calculate local mean time of rising/setting
  const T = H + RA - 0.06571 * t - 6.622;

  //adjust back to UTC
  let UT = T - lnHour;
  if (UT > 24) {
    UT = UT - 24;
  } else if (UT < 0) {
    UT = UT + 24;
  }

  const ms = UT * 60 * 60 * 1000;
  return ms;
}
