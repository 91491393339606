import React from "react";

function JumaPrayer({ prayer, prayerTime }) {
  return (
    <div className="col-xs-12 col-sm-6 col-md-3 mb-30 prayer">
      <div className="prayer-clock">
        <div className="clock">
          <h1 className="juma-emboss">{prayerTime}</h1>
        </div>
      </div>
      <h1>{prayer}</h1>
    </div>
  );
}

export default JumaPrayer;