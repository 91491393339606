export const SERVER_ADDRESS = "https://server.islamiccare.com.au"
// export const SERVER_ADDRESS = "http://localhost:7070"
export const STRIPE_PUBLIC_KEY="pk_live_51JDVu4F7EVDtMoFqAX1WnoSgEcPZkc9hhd4M5JCmsRAwVPGGZ30NPZDNSnLPZcUloZvOK1BwS0Nl7HVI2IlDFJ3v00ZJS97CNF"
export const HIJRI_START_DAY = 1
export const HIJRI_START_MONTH = 2
export const HIJRI_START_YEAR = 1443
export const GREG_START_DATE = "9/9/2021"
export const GREG_END_DATE = "31/12/2025"
export const LONGITUDE = 115.8613;
export const LATITUDE = -31.9523;
