import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./pages/Home";
import ProfileDetail from "./components/ProfileDetail";
import NewsDetail from "./components/NewsDetail";
import DonationSuccessful from "./components/DonationSuccessful";

function App() {
  
  return (
    <div id="wrapper" className="clearfix">
      <Router>
        <div className="main-content">
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/profiles/:id" component={ProfileDetail} />
            <Route path="/news/:id" component={NewsDetail} />
            <Route path="/success" component={DonationSuccessful} />
          </Switch>
        </div>
      </Router>
    </div>
  );
}

export default App;
