import React, {memo} from "react";
import moment from "moment";
import Prayer from "../components/Prayer";
import JumaPrayer from "../components/JumaPrayer";
import { PrayerCalendar } from "../PrayerCalendar"

function Prayers(props) {
  const now = new Date()
  const calendarMonth = now.getMonth().toString()
  const calendarDay = now.getDate().toString()
  const prayerTimes = PrayerCalendar[calendarMonth][calendarDay]
  const fajr = new moment(prayerTimes.fajr.iqamah, "hh:mm").format("h:mm a")
  const dohar = new moment(prayerTimes.dohar.iqamah, "hh:mm").format("h:mm a")
  const asr = new moment(prayerTimes.asr.iqamah, "hh:mm").format("h:mm a")
  const maghrib = new moment(prayerTimes.maghrib.iqamah, "hh:mm").format("h:mm a")
  const isha = new moment(prayerTimes.isha.iqamah, "hh:mm").format("h:mm a")
  return (
    <section id="prayers">
      <div className="container">
        <div className="section-title text-center">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <h2 className="line-bottom-centered text-uppercase mt-0 line-height-1">
                Prayer <span className="text-theme-colored">Timing</span>
              </h2>
              <p>
                <strong><i>for such prayers are enjoined on Believers at stated times</i></strong>
              </p>
            </div>
          </div>
        </div>
        <div className="section-content">
          <div className="row">
            <Prayer prayer="Fajr" prayerTime={fajr} />
            <Prayer prayer="Dohar" prayerTime={dohar} />
            <Prayer prayer="Asr" prayerTime={asr} />
            <Prayer prayer="Maghrib" prayerTime={maghrib} />
            <Prayer prayer="Isha" prayerTime={isha} />
            <JumaPrayer prayer="Juma" prayerTime={"Speech 12:30 pm Iqamah 1:00 pm"} />
          </div>
        </div>
      </div>
    </section>
  );
}

export default memo(Prayers);
