import React, { memo } from "react";
import Profile from "../components/Profile";
import useFetch from "../hooks/useFetch";

function Team(props) {
  const { loading, error, data } = useFetch("/profiles");
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  return (
    <section id="team">
      <div className="container">
        <div className="section-title text-center">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <h2 className="line-bottom-centered text-uppercase mt-0 line-height-1">
                Our <span className="text-theme-colored">Team</span>
              </h2>
              <p>
                Our volunteers play a vital role in helping us to provide many of our important services
                <br /> and organize various events!
              </p>
            </div>
          </div>
        </div>
        <div className="section-content">
          <div className="row">
            {data.map((p) => (
              <Profile
                key={p._id}
                id={p._id}
                name={p.name}
                education={p.education}
                description={p.description}
                url={p.files && p.files[0]}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default memo(Team);
