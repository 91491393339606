import React, { useState } from "react";

function Project({ name, description, url }) {
  const [showFull, setShowFull] = useState(false);
  return (
    <div className="col-xs-12 col-sm-6 col-md-3 mb-30 project">
      <img className="img-fullwidth" alt="" src={url} />
      <h4>{name}</h4>
      <p>{showFull ? description : description && description.substring(0, 100) + "..."}</p>
      {description && <button
        type="button"
        onClick={() => {
          setShowFull(!showFull);
        }}
        className="btn-read-more text-theme-colored"
      >
        {showFull ? "Read less" : "Read more"}
      </button>}
    </div>
  );
}

export default Project;
