import React, { useState, memo } from "react";
import { useStripe } from "@stripe/react-stripe-js";
import logo from "../assets/aiclogo.png";
import { SERVER_ADDRESS } from "../Constants";

function SiteHeader(props) {
  const [showError, setShowError] = useState(false)
  const stripe = useStripe();
  const handleSubmit = async (e) => {
    e.preventDefault()
    const amount = e.target.amount.value;

    const response = await fetch(`${SERVER_ADDRESS}/donations`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ amount }),
    });
    const session = await response.json();
    if (session.error) {
      setShowError(true)
    } else {
      stripe.redirectToCheckout({ sessionId: session.id });
    }
  };
  return (
    <header id="header" className="header">
      <div className="navbar-fixed-top">
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <img
            className="header-logo"
            src={logo}
            alt=""
            width="100"
            height="60"
          />
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 navbar-labels">
              <li className="nav-item">
                <a
                  className="nav-link"
                  aria-current="page"
                  href="#home"
                >
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#about">
                  About Us
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#announcements">
                  Announcements
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#gallery">
                  Gallery
                </a>
              </li>
              <li className="nav-item">
                <a
                  id="projectsLink"
                  className="nav-link"
                  href="#projects"
                >
                  Projects
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#prayers">
                  Prayers
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#calendar">
                  Islamic Calendar
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#team">
                  Our Team
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#footer">
                  Contact Us
                </a>
              </li>
            </ul>
            <form onSubmit={handleSubmit} className="form-inline my-2 my-lg-0">
              <div className="donation-input">
                <input
                  onChange={() => setShowError(false)}
                  className="form-control mr-sm-2"
                  name="amount"
                  type="tel"
                  placeholder="10"
                  aria-label="Donate"
                />
                {showError && <label>Min amount is $5</label>}
              </div>
              <button
                className="btn btn-outline-success my-2 my-sm-0"
                type="submit"
              >
                Donate
              </button>
            </form>
          </div>
        </nav>
      </div>
    </header>
  );
}

export default memo(SiteHeader);
