
import { FiSunrise, FiSunset, FiSun, FiMoon } from "react-icons/fi";

function CalendarDay({ sunDate, moonDate, sunrise, sunset }) {
  return (
    <>
      <div className="date-icons">
        <div>
          <FiSun />
        </div>
        <div>
          <FiMoon />
        </div>
      </div>
      <div className="dates">
        <div>{sunDate}</div>
        <div>{moonDate}</div>
      </div>
      <div className="sun-times">
        <div className="sunrise">
          <div>{sunrise}</div>
          <div>{<FiSunrise />}</div>
        </div>
        <div className="sunset">
          <div>{sunset}</div>
          <div>{<FiSunset />}</div>
        </div>
      </div>
    </>
  );
}

export default CalendarDay;
