import React from "react";
import HeroImage from "../assets/aicFront.png";
function Hero(props) {
  return (
    <section id="hero" className="bgimage">
      <div className="container-fluid">
        <div className="row caption">
          <div>
            <h1 className="text-white text-uppercase">
              <span className="font-40 font-weight-900">Australian</span><br />
              <span className="text-theme-colored font-100">Islamic</span><br />
              <span className="text-white font-100">Care INC.</span>
            </h1>
            <h4 className="text-uppercase">Standing with community</h4>
          </div>
          <div>
              <img className="caption-img" src={HeroImage} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
