export const formatDate = (st, et) => {
    const t1 = new Date(st);
    const t2 = new Date(et);
    let dateOptions = {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    let timeOptions = { hour: "numeric", minute: "numeric", hour12: true, timeZone: 'UTC' };
    if (Object.prototype.toString.call(t1) === "[object Date]") {
      // it is a date
      if (isNaN(t1.getTime())) {
        return new Date(st).toLocaleString("en-US", dateOptions);
      } else {
        // date is valid
        if (Object.prototype.toString.call(t2) === "[object Date]") {
          if (isNaN(t2.getTime())) {
            return `${t1.toLocaleString(
              "en-US",
              dateOptions
            )} at ${t1.toLocaleString("en-US", timeOptions)}`;
          } else {
            return `${t1.toLocaleString( "en-US", dateOptions )} from
             ${t1.toLocaleString( "en-US", timeOptions )} - ${t2.toLocaleString("en-US", timeOptions)}`;
          }
        }
      }
    } else {
      return "";
    }
  }