import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import useFetch from "../hooks/useFetch";
import { useStoreContext } from "../Store";
import { formatDate } from "../utils";

function NewsDetail(props) {
  const { setHomePage } = useStoreContext();
  const { id } = useParams();
  const { loading, error, data } = useFetch(`/news/${id}`);
  const dateTime = data && formatDate(data.startTime, data.endTime);
  useEffect(() => setHomePage(false));
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;
  return (
    <div className="profile-card">
      <a href="/" className="btn btn-primary">
        Go back
      </a>
      <div className="d-flex justify-content-center align-items-center">
        <div className="d-flex flex-column justify-content-start align-items-start mt-5 ml-5">
          {data && (
            <a target="_blank" href={data.files[0]}></a>
          //   <iframe
          //   src={`https://drive.google.com/viewerng/viewer?embedded=true&url=${data.files[0]}#toolbar=0&scrollbar=0`}
          //   frameBorder="0"
          //   scrolling="auto"
          //   height="800px"
          //   width="1050px"
          //   title="PDF Viewer"
          // ></iframe>
          )}
          <h4>{data.heading}</h4>
          <h6>{dateTime}</h6>
          <h6>{data.address}</h6>
          <p>{data.description}</p>
        </div>
      </div>
    </div>
  );
}

export default NewsDetail;
