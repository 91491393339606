import React from "react";

function Profile({ id, name, education, description, url }) {
  return (
    <div className="col-xs-12 col-sm-6 col-md-3 mb-30 profile">
      <img alt="" src={url} />
      <div>
        <h5>{name}</h5>
        <p>
          <u>{education}</u>
        </p>
        <p>{description.substring(0, 50)}...</p>
        <a href={`profiles/${id}`} className="btn-read-more text-theme-colored">
          Read more...
        </a>
      </div>
    </div>
  );
}

export default Profile;
